//Declarem el import que utilitzarem
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

//Declarem el titol que tindra la pestanya del nostre formulari un cop a la web
export class AppComponent {}
