import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalDialogComponent } from '../../shared/modales/modal-dialog/modal-dialog.component';
import { UserServiceService } from './user-service.service';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(public dialog: MatDialog, public userSrv: UserServiceService) { }
  /**
   * Método del servicio
   * que permite
   * abrir el modal
   * con los parámetros
   * correspondientes
   * @param componente
   * @param objTraducciones
   * @param tipeModal
   * @param mensajeError
   */
  public mostrarModal(componente: ComponentType<unknown>, data: any): MatDialogRef<unknown, any> {
    return this.dialog.open(componente, { data: data, disableClose: true, panelClass: 'customDialog' });
  }

  /**
   * Método que abre el asistente
   * del DNI y donde se le ponen las
   * medidas
   * @param componente
   */
  public mostrarAsistente(componente: ComponentType<unknown>): MatDialogRef<unknown, any> {
    return this.dialog.open(componente, { disableClose: true, panelClass: 'customDialog' });
  }

  /**
* Método que abre el pop-up
* con los textos de traducciones
* corrspondientes
* @param modalParamDesc traducciones
* @param tipeModal
* @param mensajeError opcional, solo si viene del back-end
*/
  public showDialog(modalParamDesc: any, tipeModal: string, mensajeError?: string) {
    const data = {
      objTraducciones: {
        titulo: modalParamDesc.title,
        subtitulo: modalParamDesc.subtitle,
        boton: modalParamDesc.button
      },
      tipeModal: tipeModal,
      mensajeError: mensajeError
    }
    this.mostrarModal(
      ModalDialogComponent,
      data
    );
  }
}
