import { Component,  Input, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UserServiceService } from '../../../core/providers/user-service.service';
import { SessionService } from '@mutual-lib/frontend';

@Component({
  selector: 'form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent {
  /*FormControl del input*/
  @Input() control: UntypedFormControl;
  /*Etiqueta del
  campo al que corresponde
  el input*/
  @Input() controlLabel: string;
  /*Nombre del formControl*/
  @Input() name: string;

  public userSrv: UserServiceService;
  public sessionSrv: SessionService

  constructor() {
    this.userSrv = inject(UserServiceService);
    this.sessionSrv = inject(SessionService);

  }
}
