import { HttpRequest } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalErrorComponent } from '../../../shared/modals/modal-error/modal-error.component';
import { isNil } from 'lodash';
import { ModalErrorPerfiladoComponent } from '../../../shared/modals/modal-error-perfilado/modal-error-perfilado.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  /**
   * Se lanza un evento cuando el pop up de error
   * se active para desactivar el spinner
   * de carga
   */
  public msgErrorActivado$: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * Usuario que se mostrará en los logs
   * de elk
   */
  public usuarioLog!: string;

  /** Constructor */
  constructor(public dialog: MatDialog) { }

  /**
   * Método que recibe la request que ha fallado
   * y que se encarga de montar y mostrar
   * el pop up con la informacion del error
   * @param req tipo httpRequest
   * @param error error recibido
   */
  public showMsgError(req: HttpRequest<any>, error: any, usuario: string) {
    this.msgErrorActivado$.emit(true);
    let ip: string = '';
    let errorDev: string = '';
    ({ ip, errorDev } = this.getErrorMsg(error, ip, errorDev));

    this.dialog.open(ModalErrorComponent, {
      height: 'auto',
      width: '50%',
      data: {
        ip: ip,
        mensajeTecnico: errorDev,
        url: req.urlWithParams,
        traceid: req.headers.get('traceid'),
        navegador: navigator.userAgent,
        usuario: usuario,
        fecha: new Date()
      },
      panelClass: "custom-modal-error"
    });
  }

  /**
   * Obtener textos para mostrar
   * en el pop up de error
   * @param error de tipo httpErrorResponse
   * @param errorCliente descripcion del error friendly
   * para el usuario
   * @param ip cadena de texto
   * @param errorDev error técnico para desarrolladores
   * @returns objeto con los errores a mostrar
   */
  public getErrorMsg(error: any, ip: string, errorDev: string) {
    if (!isNil(error.error)
      && !isNil(error.error.devMsg)) {
      const info = error.error.devMsg.split('||');
      ip = info.length === 2 ? info[0] : '';
      errorDev = info.length === 2 ? info[1] : '';
    }
    return { ip, errorDev };
  }

   /**
   * Método que abre el modal
   * de error cuando el usuario
   * no tiene acceso
   */
   public showMsgErrorPerfilado() {
    this.dialog.open(ModalErrorPerfiladoComponent, {disableClose:true});
  }
}
