import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UtilsService } from '../../../core/providers/utils/utils.service';

@Component({
  selector: 'lib-modal-error-perfilado',
  templateUrl: './modal-error-perfilado.component.html',
  styleUrls: ['./modal-error-perfilado.component.scss'],
})
export class ModalErrorPerfiladoComponent {
  /**
   * Método constructor
   */
  constructor(
    private utilsSrv: UtilsService,
    public dialogRef: MatDialogRef<ModalErrorPerfiladoComponent>
  ) { }

  /**
   * Método que cierra
   * el modal
   */
  closeDialog() {
    this.dialogRef.close();
    this.utilsSrv.redireccionarUrlAnterior();
  }
}
