import { Component, OnInit } from '@angular/core';
import { AsistenteService } from 'src/app/core/providers/asistente.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalService } from '../../../core/providers/modal.service';
import { CaraDocumentoComponent } from '../../../modules/asistente-documento/cara-documento/cara-documento.component';
import { UserServiceService } from '../../../core/providers/user-service.service';
import { SessionService } from '@mutual-lib/frontend';

@Component({
  selector: 'app-modal-asistente',
  templateUrl: './modal-asistente.component.html',
  styleUrls: ['./modal-asistente.component.scss']
})
export class ModalAsistenteComponent implements OnInit {

  constructor(
    public asistenteSrv: AsistenteService,
    public userSrv: UserServiceService,
    public modalSrv: ModalService,
    public dialogRef: MatDialogRef<ModalAsistenteComponent>,
    public sessionSrv: SessionService
  ) { }

  ngOnInit(): void {
    this.asistenteSrv.styleCDK();
  }

  /**
   * Método para resetear
   * la digitalización
   * del DNI
   */
  resetarDigitalizacion() {
    this.asistenteSrv.showInputsOCR = false;
    this.closeDialog();
    this.modalSrv.mostrarAsistente(CaraDocumentoComponent);
  }

  /**
   * Método para cerrar
   * el modal
   */
  closeDialog() {
    this.dialogRef.close();
  }
}
