import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { UtilsService } from '../providers/utils/utils.service';
import { SessionService } from '../providers/session/session.service';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  /**
 * Método constructor
 * @param utilSrv servicio util
 */
  constructor(private utilSrv: UtilsService,
    private sessionSrv: SessionService) {
  }

  /**
   * Captura todas las peticiones http,
   * almacena las nuevas y si son
   * repetidas devuelve la almacenada para
   * evitar repetir llamadas a servicios
   * @param req peticion
   * @param next de tipo http handler
   * @returns peticion
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let result: Observable<HttpEvent<unknown>> = next.handle(req);
    const apisToCache = this.sessionSrv.getLibConfig().cache.apisCache;
    /**
     * Se comprueba que sea peticion GET y que
     * deba ser cacheada
     */
    if (apisToCache.some((e: string) => req.url.includes(e))
      && this.utilSrv.compararValores(req.method, 'GET')
    ) {
      /**
       * Buscamos si la petición ya se ha hecho previamente
       * y si está almacenada en el Map
       * Se busca por url
       */
      const cachedRes: any = this.sessionSrv.cache.get(req.url);
      /**
       * Si el elemento existe y no ha pasado una hora desde que se obtuvo
       * la respuesta del servicio, se devuelve la respuesta almacenada.
       * En caso contrario se repite la petición
       */
      if (this.utilSrv.existeElemento(cachedRes) && this.isPeticionValida(cachedRes)) {
        result = of(cachedRes.response);
      } else {
        /**
         * En caso de que exista la petición pero haya
         * pasado el tiempo de validez, eliminamos el registro
         * almacenado y llamamos de nuevo al endpoint
         */
        if (this.utilSrv.existeElemento(cachedRes)) {
          this.sessionSrv.cache.delete(req.url);
        }
        result = next.handle(req).pipe(
          tap(stateEvent => {
            if (stateEvent instanceof HttpResponse) {
              /**
               * Se almacena en cache
               */
              this.sessionSrv.cache.set(req.url,
                {
                  response: stateEvent.clone(),
                  date: new Date()
                }
              );
            }
          })
        )
      }
    }
    return result;
  }

  /**
   *
   * @param cachedRes respuesta
   * @returns
   */
  isPeticionValida(cachedRes: any) {
    let valida = false;
    if (this.utilSrv.existeElemento(cachedRes)) {
      valida = this.utilSrv.getMinDiff(new Date(), cachedRes?.date) < this.sessionSrv.getLibConfig().cache.tiempoValidezCache;
    }
    return valida;
  }
}
