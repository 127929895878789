<div class="form flex column">
  <form [formGroup]="asistenteSrv.ocrForm" (ngSubmit)="asistenteSrv.asistenteFinalizado()">
    <p class="txtTitle mt-0per" [ngClass]="sessionSrv.responsive ? 'responsive' : ''">{{ "asistente.titleDatos" |
      translate }}</p>
    <p class="txtInfo" [ngClass]="sessionSrv.responsive ? 'responsive' : ''">{{ ( asistenteSrv.camposEditables ?
      "asistente.errorDatos" : "asistente.infoDatos") | translate }}</p>
    <div class="flex column">
      <p class="label-form" [ngClass]="sessionSrv.responsive ? 'responsive' : ''">DNI/NIE</p>
      <input class="input-form-datosDocumento" [ngClass]="sessionSrv.responsive ? 'responsive' : ''" formControlName="dni"
        autocomplete="username" type="text" />
      <p class="footer mt-1per" [ngClass]="sessionSrv.responsive ? 'footerRes' : ''">{{ 'validators.formatoDNI' | translate
        }}</p>
      <span class="span">
        <mat-error *ngIf="dni?.hasError('required') && dni?.touched" class="validaciones">
          {{'validators.requerido' |translate}}</mat-error>
      </span>
    </div>

    <div class="flex row" style="gap:30px;">
      <div>
        <p class="label-form" [ngClass]="sessionSrv.responsive ? 'responsive' : ''">{{ 'formInputs.fechaNac' | translate }}
        </p>
        <mat-form-field class="form-field" floatLabel="never">
          <input formControlName="fecha" matInput [matDatepicker]="pickerFecha" placeholder="dd/mm/aaaa *">
          <mat-datepicker-toggle matSuffix [for]="pickerFecha" class="botonIcono"></mat-datepicker-toggle>
          <mat-datepicker #pickerFecha></mat-datepicker>
        </mat-form-field>
        <span class="span">
          <mat-error *ngIf="fecha?.hasError('required') && fecha?.touched" class="validaciones">
            {{'validators.requerido' |translate}}</mat-error>
        </span>
      </div>
      <div>
        <p class="label-form" [ngClass]="sessionSrv.responsive ? 'responsive' : ''">{{ 'formInputs.fechaCad' | translate }}
        </p>
        <mat-form-field class="form-field" floatLabel="never">
          <input formControlName="fechacaducidadnif" matInput [matDatepicker]="pickerCad" placeholder="dd/mm/aaaa *">
          <mat-datepicker-toggle matSuffix [for]="pickerCad" class="botonIcono"></mat-datepicker-toggle>
          <mat-datepicker #pickerCad></mat-datepicker>
        </mat-form-field>
        <span class="span">
          <mat-error *ngIf="fechacaducidadnif?.hasError('required') && fechacaducidadnif?.touched" class="validaciones">
            {{'validators.requerido' |translate}}</mat-error>
        </span>
      </div>
    </div>

    <div class="flex justify-center finalizarBtn" [ngClass]="sessionSrv.responsive && !userSrv.tabletMode ? 'w-70' : 'w-35'"
      [class.w-50]="userSrv.tabletMode">
      <button [ngClass]="asistenteSrv.ocrForm.invalid ? 'disabledGris' : 'fullBlueButton'" [disabled]="asistenteSrv.ocrForm.invalid" type="submit">
        {{ "asistente.botonFinalizar" | translate }}
      </button>
    </div>
  </form>
</div>
