/**
 * Es declaren els imports que s'utilitzaran
 */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserFormComponent } from './modules/user-form/user-form.component';

//Declarem les rutes que tenim del formulari i cap a quin component han d'anar quan es criden
/**
 * Es declaren les rutes possibles del
 * formulari i a quin component s'ha
 * d'accedir quan es criden. Hi ha
 * dues rutes en català (una per Medicorasse
 * i l'altre no) i dues rutes en castellà
 * (una per Medicorasse i l'altre no).
 * En aquest cas el component al que
 * accedeixen les rutes es sempre el mateix
 * ja que es tracta del mateix formulari
 * exceptuant l'idioma i les imatges.
 *
 */
export const routes: Routes = [
  { path: 'ca/contractar-asseguranca-mutualista-jove', component: UserFormComponent },
  { path: 'es/contratar-seguro-mutualista-joven', component: UserFormComponent },
  { path: 'ca/contractar-asseguranca-mutualista-jove/medicorasse', component: UserFormComponent },
  { path: 'es/contratar-seguro-mutualista-joven/medicorasse', component: UserFormComponent },
  { path: '', redirectTo: 'ca/contractar-asseguranca-mutualista-jove', pathMatch: "full" }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
