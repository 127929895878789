import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mm-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent {
  /**Ruta para obtener la imagen del icono
   * Ej si está en la librería de arquitectura:
   * './assetsArq/img/icons/add.svg'
  */
  @Input() srcIcon!: string;
  /**key del fichero de traducciones para el label del boton */
  @Input() label!: string;
  /**Nombre clase estilo */
  @Input() cssClass!: string;
  /**
   * Bloquea o habilita el click en el botón
   */
  @Input() disabledButton!: boolean;
  /**Notifica al componente padre que se ha hecho click en el botón */
  @Output() notifyClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Se llama desde el html
   * y notifica el evento click
   *  */
  emitClick() {
    this.notifyClick.emit(true);
  }
}
