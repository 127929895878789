<!-- Widget o tarjeta para redireccionar l portal de prestaciones -->
<div class="main-container">
  <!-- icono error -->
  <div class="flex justify-center align-center">
    <img [src]="imgModalPath" id="valido-ok" class="img valido-ok" alt="icono">
  </div>
  <!-- título -->
  <div class="title mb-5">{{data.objTraducciones.titulo}}</div>
  <!-- subtítulo -->
  <div class="flex column justify-center align-center subtitle mb-5p">
    <div *ngIf="data.tipeModal=='info' || data.tipeModal=='errorDocu'">{{data.objTraducciones.subtitulo}}</div>
    <div *ngIf="data.tipeModal=='success'||data.tipeModal=='error'">{{data.mensajeError}}</div>
  </div>
  <!-- Botón para cerrar modal -->
  <div class="flex justify-center align-center">
    <button class="button" (click)="closeDialog()">{{data.objTraducciones.boton}}</button>
  </div>
</div>
