import { Injectable, inject } from '@angular/core';
import waitUntil from 'async-wait-until';
import Keycloak from 'keycloak-js';
import { isNil } from 'lodash';
import { LevelType } from '../../enum/levelType.enum';
import { ApiToken } from '../../models/api-token';
import { LogService } from '../logs/log.service';
import { ModalService } from '../modal/modal.service';
import { SessionService } from '../session/session.service';
import { CookieService } from '../storage/cookie.service';
import { UtilsService } from '../utils/utils.service';
import { ProfileService } from './profile.service';
import { TokenService } from './token.service';
import { DecisionModalComponent } from '../../../shared/modals/decision-modal/decision-modal.component';

@Injectable({
  providedIn: 'root',
})
export class KeycloakService {
  /**
   * Objeto tipo keycloak
   */
  public keycloak!: Keycloak;
  /**
   * Variable que almacena si se ha autenticado
   * correctamente o no
   */
  public isLoggedIn = false;

  /**
   * Variable que se pondrá a true cuando
   * ambos token se refresquen
  */
  public tokensRefrescados!: boolean | undefined;
  public refrescoAutomatico!: boolean | undefined;
  public interaccionModalExp!: boolean | undefined;
  /**
   * Variable que indica si el modal
   * de expiracion de sesión está abierto o no
   */
  private modalSesionOpen = false;
  /**Servicios */
  private tokenSrv!: TokenService;
  private sessionSrv!: SessionService;
  private logSrv!: LogService;
  private utilsSrv!: UtilsService;
  private cookieSrv!: CookieService;
  private profileSrv!: ProfileService;
  private modalSrv!: ModalService;

  /**
   * Constructor inyeccion de dependencias
   */
  constructor() {
    this.tokenSrv = inject(TokenService);
    this.sessionSrv = inject(SessionService);
    this.logSrv = inject(LogService);
    this.utilsSrv = inject(UtilsService);
    this.cookieSrv = inject(CookieService);
    this.profileSrv = inject(ProfileService);
    this.modalSrv = inject(ModalService);
  }

  /**
   * Método que inicializa
   * el login con keycloak
   * Si el login es correcto, se recupera la
   * información del usuario y se almacenan los datos de la sesión,
   * además se obtiene el perfilado
   */
  public async initializeKeycloak(url?: string) {
    this.registrarActividad();
    this.sessionSrv.cache = new Map();
    const configKeycloak = this.sessionSrv.config.keycloakConfig![0];
    this.keycloak = new Keycloak({
      url: configKeycloak.url,
      realm: configKeycloak.realm,
      clientId: configKeycloak.clientId
    });
    await this.keycloak.init({
      onLoad: 'login-required',
      redirectUri: this.utilsSrv.existeElemento(url) ?
        (window.location.origin + '/' + this.sessionSrv.config.applicationName.toLowerCase() + '/' + url)
        : window.location.href
    }).then(async (authenticated: any) => {
      this.isLoggedIn = authenticated;
      await this.checkAuthentication();
    }).catch(() => {
      this.isLoggedIn = false;
    });
  }
  /**
   * Valida si se ha iniciado sesion
   * correctamente y genera token y perfil
   *
   * */
  private async checkAuthentication() {
    const traceId = this.utilsSrv.generateTraceId();
    if (this.isLoggedIn) {
      this.sessionSrv.keycloakInfo = this.keycloak;
      this.sessionSrv.currentUser =
        this.keycloak.idTokenParsed?.['preferred_username'] ?? 'Usuario no disponible';
      this.sessionSrv.ofuscarEmail();
      const userMask = this.sessionSrv.getCurrentUserMask();
      await this.tokenSrv.getWSO2Token();
      this.logSrv.insertLog(
        traceId,
        'checkAuthentication',
        'initializeKeycloak',
        LevelType.info,
        'Token generado correctamente',
        userMask
      );
      await this.profileSrv.loadRutas(traceId);
      await this.profileSrv.loadPermisosFuncionales(traceId);
      await this.profileSrv.loadMenus(traceId);
      await waitUntil(() => this.utilsSrv.existeElemento(this.profileSrv.permisosInicioKeycloak), { timeout: 120 * 1000 });
      if (this.profileSrv.permisosInicioKeycloak) {
        this.sessionSrv.loginOk = true;
        this.activarControlSesionActiva(traceId);
      }
    } else {
      this.sessionSrv.loginOk = false;
    }
  }
  /**
   * Método que controla la sesisión actual
   * una vez que el login se ha hecho correctamente.
   * Se valida que la cookie fechaUltimaActividad exista en todo momento,
   * y en caso de no existir se cierra la sesión automáticamente.
   * Si existe, valida entonces la actividad del ususario.
   * En base a la fecha de inicio y expiración de sesión se obtiene la duración en minutos del token
   * actual de keycloak.
   * @param traceId identificador de la traza para el log.
   */
  private activarControlSesionActiva(traceId: string) {
    this.logSrv.insertLog(traceId, 'activarControlSesionActiva', 'checkAuthentication', LevelType.info, 'Inicio control sesion activa', this.sessionSrv.getCurrentUserMask());
    const fechaInicioSesion = new Date(this.keycloak.tokenParsed?.iat! * 1000)
    const fechaExpiracionSesion = new Date(this.keycloak.tokenParsed?.exp! * 1000);
    const duracionToken = this.utilsSrv.getMinDiff(fechaInicioSesion, fechaExpiracionSesion);
    const interval = setInterval(() => {
      if (this.cookieSrv.checkCookie('fechaUltimaActividad')) {
        this.checkActividadUsuario(duracionToken, interval);
      } else {
        this.logSrv.insertLog(
          traceId,
          'activarControlSesionActiva',
          'checkAuthentication',
          LevelType.info,
          'Se cierra la sesión por pérdida de la cookie fechaUltimaActividad',
          this.sessionSrv.getCurrentUserMask());
        clearInterval(interval);
        this.logout(true);
      }
    });
  }
  /**
   * Método que comprueba que el usuario esté activo. Para definir el tiempo de inactividad,
   * se obtiene la duración total del token (ejemplo: 23 minutos) y se le restan los minutos
   * definidos por configuración (minutosEsperaUsuario - ejemplo: 3 minutos)
   * que se le dan al usuario de margen para reaccionar ante el aviso de expiración de sesión.
   * Si el usuario está inactivo, se cierra la sesión.
   * Si está activo se comprueba y si quedan menos de 'minutosEsperaUsuario' minutos para que
   * expire el token, se refrescarán los token de Keycloak y WSO2.
   * @param duracionToken minutos totales de validez del token de keycloak. Ejemplo: 23 minutos
   * @param interval interval activo
  */
  private checkActividadUsuario(duracionToken: number, interval: any) {
    const minutosEsperaUsuario = this.sessionSrv.getLibConfig().minutosEsperaUsuario;
    const traceId = this.utilsSrv.generateTraceId();
    const tiempoInactividad = duracionToken - minutosEsperaUsuario;
    if (this.isUsuarioActivo(tiempoInactividad)) {
      //Se pasan los minutos en segundos
      if (this.keycloak.isTokenExpired(minutosEsperaUsuario * 60)) {
        this.logSrv.insertLog(
          traceId,
          'activarControlSesionActiva',
          'checkAuthentication',
          LevelType.info,
          'Usuario activo y quedan ' + minutosEsperaUsuario + ' minutos',
          this.sessionSrv.getCurrentUserMask());
        clearInterval(interval);
        this.refrescoAutomatico = true;
        this.refrescarTokens(traceId);
      }
    } else {
      clearInterval(interval);
      this.logSrv.insertLog(
        traceId,
        'activarControlSesionActiva',
        'checkAuthentication',
        LevelType.info,
        'Usuario inactivo más de ' + tiempoInactividad + ' minutos. Se abre modal de expiración.',
        this.sessionSrv.getCurrentUserMask());
      this.mostrarModalExpiracion(traceId);
    }
  }

  /**
   * Método que se encarga de iniciar
   * el refresco de los tokens de la sesión donde
   * el primero en refrescar es el de Keycloak
   * @param traceId identificador traza de los logs
   */
  refrescarTokens(traceId: string) {
    let desc = 'Se inicia el proceso de refresco de tokens';
    if (this.refrescoAutomatico) {
      desc += ' automático';
    }
    this.logSrv.insertLog(
      traceId,
      'refrescarTokens',
      'mostrarModalExpiracion',
      LevelType.info,
      desc,
      this.sessionSrv.getCurrentUserMask());
    const refrescoOK = 'Se ha refrescado correctamente el token de ';
    const refrescoKO = 'Fallo al refrescar el token de ';

    this.refrescarTokenKeycloak(traceId, refrescoOK, refrescoKO);
  }
  /**
   *  Método que se encarga de refrescar
   * el token de keycloak. Si este se refreca correctamente
   * entonces se refresca el de WSO2, pero si falla
   * se cierra la sesión automáticamente.
   * @param traceId identificador traza de los logs
   * @param refrescoOK literal a mostrar común cuando el refresco ha sido correcto
   * @param refrescoKO literal a mostrar común cuando el refresco ha fallado
   */
  private refrescarTokenKeycloak(traceId: string, refrescoOK: string, refrescoKO: string) {
    this.keycloak.updateToken(-1).then(() => {
      this.sessionSrv.keycloakInfo = this.keycloak;
      this.logSrv.insertLog(
        traceId,
        'refrescarTokens',
        'mostrarModalExpiracion',
        LevelType.info,
        refrescoOK + 'Keycloak',
        this.sessionSrv.getCurrentUserMask());
      this.refrescarTokenWSO2(traceId, refrescoOK, refrescoKO);
    }).catch(async() => {
      this.tokensRefrescados = false;
      await waitUntil(() => this.utilsSrv.existeElemento(this.sessionSrv.errorObject));
      this.logSrv.insertLog(
        traceId,
        'refrescarTokens',
        'mostrarModalExpiracion',
        LevelType.info,
        refrescoKO + 'Keycloak. Error: ' + JSON.stringify(this.sessionSrv.errorObject),
        this.sessionSrv.getCurrentUserMask());
      this.sessionSrv.showSpinner = false;
      this.logout(true);
    });
  }
  /**
 *  Método que se encarga de refrescar
 * el token de WSO2. Si este se refreca correctamente
 * se activa de nuevo el control de la sesión, en caso contrario
 * se hace logout.
 * @param traceId identificador traza de los logs
 * @param refrescoOK literal a mostrar común cuando el refresco ha sido correcto
 * @param refrescoKO literal a mostrar común cuando el refresco ha fallado
 */
  private refrescarTokenWSO2(traceId: string, refrescoOK: string, refrescoKO: string) {
    this.tokenSrv.generateToken().subscribe({
      next: (refreshToken: ApiToken) => {
        this.tokenSrv.setToken(refreshToken);
        this.tokensRefrescados = true;
        this.logSrv.insertLog(
          traceId,
          'refrescarTokens',
          'mostrarModalExpiracion',
          LevelType.info,
          refrescoOK + 'WSO2',
          this.sessionSrv.getCurrentUserMask());
        this.activarControlSesionActiva(traceId);
        if (this.refrescoAutomatico) {
          this.tokensRefrescados = undefined;
        }
      }, error: async() => {
        this.tokensRefrescados = false;
        await waitUntil(() => this.utilsSrv.existeElemento(this.sessionSrv.errorObject));
        this.logSrv.insertLog(
          traceId,
          'refrescarTokens',
          'mostrarModalExpiracion',
          LevelType.info,
          refrescoKO + 'WSO2. Error: ' + JSON.stringify(this.sessionSrv.errorObject),
          this.sessionSrv.getCurrentUserMask());
        this.logout(true);
      }
    });
  }
  /**
    * Se encarga de abrir el modal informativo de que
    * la sesión activa va a expirar.
    * Si el usuario no interactua con el modal, pasados N min definifos en la config
    * campo minutosEsperaUsuario se cerrará automáticamente y la sesión activa también,
    * haciendo logout con keycloak.
    * @param traceId identificador de traza
    */
  mostrarModalExpiracion(traceId: string) {
    if (!this.modalSesionOpen) {
      this.logSrv.insertLog(
        traceId,
        'mostrarModalExpiracion',
        'activarControlSesionActiva',
        LevelType.info,
        'Se abre modal de expiración.',
        this.sessionSrv.getCurrentUserMask());
      const minutosEsperaUsuario = this.sessionSrv.getLibConfig().minutosEsperaUsuario;
      this.interaccionModalExp = false;
      const ref = this.modalSrv.abrirModalExpiracionSesion();
      this.modalSesionOpen = true;
      /**Cierre automatico sesión por inactividad */
      const interval: any = setInterval(() => {
        if (!this.interaccionModalExp) {
          this.logSrv.insertLog(
            traceId,
            'mostrarModalExpiracion',
            'activarControlSesionActiva',
            LevelType.info,
            'Se cierra sesión automáticamente por inactividad.',
            this.sessionSrv.getCurrentUserMask());
          this.logout(true);
          clearInterval(interval);
        }
        //minutos en ms
      }, minutosEsperaUsuario * 60000);
      const inst = ref.componentInstance;
      /**Lógica botón Cerrar sesión */
      this.cerrarSesionActiva(inst, traceId, interval);
      /**Lógica botón Continuar */
      this.mantenerSesionActiva(inst, traceId, interval);
    }
  }
  /**
   * En este método se detecta si el usuario ha hecho click
   * en el botón de cerrar la sesión.
   * @param inst instancia del modal
   * @param traceId identificador de la traza
   */
  private cerrarSesionActiva(inst: DecisionModalComponent, traceId: string, interval: any) {
    inst.clickBtn1.subscribe(() => {
      this.interaccionModalExp = true;
      this.logSrv.insertLog(
        traceId,
        'mostrarModalExpiracion',
        'activarControlSesionActiva',
        LevelType.info,
        'El usuario ha decicido no continuar con la sesión.',
        this.sessionSrv.getCurrentUserMask());
      this.modalSesionOpen = false;
      clearInterval(interval);
      this.logout(true);
    });
  }
  /**
   * En este método se detecta si el usuario ha hecho click
   * en el botón de mantener la sesión.
   * En este caso se refrescan los tokens y la cookie
   * de ultima actividad del usuario.
   * @param inst instancia del modal
   * @param traceId identificador de la traza
   * @param interval interval asociado al control sobre el modal.
   */
  private mantenerSesionActiva(inst: DecisionModalComponent, traceId: string, interval: any) {
    inst.clickBtn2.subscribe(async () => {
      this.interaccionModalExp = true;
      this.logSrv.insertLog(
        traceId,
        'mostrarModalExpiracion',
        'activarControlSesionActiva',
        LevelType.info,
        'El usuario desea continuar con la sesión.',
        this.sessionSrv.getCurrentUserMask());

      this.refrescoAutomatico = false
      this.refrescarTokens(traceId);
      await waitUntil(() => this.utilsSrv.existeElemento(this.tokensRefrescados), { timeout: 120 * 1000 });
      if (this.tokensRefrescados) {
        this.cookieSrv.crearCookie('fechaUltimaActividad', JSON.stringify(new Date().getTime()), undefined);
        this.logSrv.insertLog(
          traceId,
          'mostrarModalExpiracion',
          'activarControlSesionActiva',
          LevelType.info,
          'El usuario ha mantenido la sesión y la acción se ha registrado como última actividad.',
          this.sessionSrv.getCurrentUserMask());
        this.tokensRefrescados = undefined;
        this.modalSesionOpen = false;
      }
      clearInterval(interval);
    });
  }
  /**
   * Método que gestiona el cierre
   * de sesión comunicandose con keycloak
   * que a su vez se comunica con microsoft
   */
  public logout(sessionExpirada: boolean) {
    const traceId = this.utilsSrv.generateTraceId();
    const userMask = this.sessionSrv.getCurrentUserMask();
    let pathRedirecion = this.sessionSrv.config.keycloakConfig![0].urlRedirectLogout;
    if (sessionExpirada) {
      pathRedirecion = this.sessionSrv.getLibConfig().urlRedirectSesionExpirada;
    }
    this.keycloak.logout({
      redirectUri: window.location.origin + '/' + this.sessionSrv.getIdiomaActual() + pathRedirecion
    }).then(() => {
      this.logSrv.insertLog(
        traceId,
        'logout',
        'click',
        LevelType.info,
        'Cerrada correctamente la sesión',
        userMask
      );
      this.keycloak.clearToken();
    }).catch((error: any) => {
      this.logSrv.insertLog(
        traceId,
        'logout',
        'click',
        LevelType.error,
        'Fallo al cerrar la sesión. Error: ' + error,
        userMask
      );
    });
  }

  /**
   * Método que revisa la actividad del usuario en funcion de:
   * la fecha de la ultima actividad registrada por el usuario,
   * la fecha actual y el tiempo definido para considerar que hay inactividad.
   * Este último se pasa por parámetro y se calcula a partir de la duración total del
   * token menos el tiempo que se da al usuario para reaccionar ante un posible
   * cierre de sesión.
   * Mientras los minutos que hayan pasado de la última actividad no superen al tiempo de
   * inactividad, se considera que el usuario sigue activo.
   * @returns boolean
   */
  isUsuarioActivo(tiempoInactividad: number) {
    const ultima = this.cookieSrv.getCookie('fechaUltimaActividad');
    const minLeft = this.utilsSrv.getMinLeft(new Date(Number(ultima)));
    return (!isNil(ultima) && ultima !== '') ? (minLeft < tiempoInactividad) : false;
  }

  /**
   * Método que cada vez que detecta
   * actividad por parte del usuario
   * actualiza o crea la cookie fechaUltimaActividad
   */
  registrarActividad() {
    this.cookieSrv.crearCookie('fechaUltimaActividad', JSON.stringify(new Date().getTime()), undefined);
  }
}
