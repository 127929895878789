import { Injectable, inject } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { SessionService } from '../session/session.service';
import { LogService } from '../logs/log.service';
import { LevelType } from '../../enum/levelType.enum';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {
  /**Clase para detectar cambios
   * de tamaño en la ventana */
  public breakpointObserver!: BreakpointObserver;
  /**Servicios */
  private sessionSrv!: SessionService;
  private logSrv!: LogService;
  private utilsSrv!: UtilsService;

  /**
   * Método constructor
   */
  constructor() {
    this.breakpointObserver = inject(BreakpointObserver);
    this.sessionSrv = inject(SessionService);
    this.logSrv = inject(LogService);
    this.utilsSrv = inject(UtilsService);
  }

  /**
   * Método que modifica el valor
   * de la variable responsive. En caso
   * de estar en un dispositivo con un width
   * menor de 1279.98px se activará el disenyo
   * responsive. Para tamaño superior se activa
   * el disenyo de escritorio.
   */
  public checkResponsive(pixelMax?: string) {
    const pixel = pixelMax ?? '1279.98';
    this.breakpointObserver.observe(['(max-width: ' + pixel + 'px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.sessionSrv.responsive = true;
        } else {
          this.sessionSrv.responsive = false;
        }
        this.logSrv.insertLog(
          this.utilsSrv.generateTraceId(),
          'checkResponsive',
          'subscribe observer',
          LevelType.info,
          'Modo: ' + state.matches ? 'responsive' : 'escritorio',
          this.sessionSrv.getCurrentUserMask()
        );
      });
  }

}
