export enum Regex {
  email = '[\\w_\\.]+@([\\w_]+\\.)+[\\w_]{2,4}',
  telefono = '[6_7_8_9]{1}[0-9]{8}',
  cp = '[0-9]{5}',
  numcol = '^[0-9]*$',
  texto = '^[a-zA-ZñÑáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙ ]+$',
  specialChars = '^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙºª, \/-]+$'
}
/**
 * Enumeracion expresiones regulares
 * para los campos del formulario
 * con inputs libres.
 * Se usa specialChars para limitar carácteres
 * especiales por seguridad.
 */
