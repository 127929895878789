import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import waitUntil from 'async-wait-until';
import { DecisionModalComponent } from '../../../shared/modals/decision-modal/decision-modal.component';
import { SessionService } from '../session/session.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  /**
   * Variables
   */
  private utilSrv: UtilsService;
  private dialog: MatDialog;
  private sessionSrv: SessionService;
  public modalSesionOpen!: boolean;
  /**
   * Método constructor
   * inyección de servicios
   */
  constructor() {
    this.utilSrv = inject(UtilsService);
    this.dialog = inject(MatDialog);
    this.sessionSrv = inject(SessionService);
  }

  /**
   * Muestra el modal de aviso
   * al hacer click en el botón
   * 'Salir del proceso'
   */
  mostrarAvisoSalirProceso() {
    const ref: MatDialogRef<DecisionModalComponent> = this.dialog.open(DecisionModalComponent, {
      data: {
        urlImagen: "./assetsArq/img/logout/warning_logout.svg",
        title: "modalSalirProceso.title",
        subtitle1: "modalSalirProceso.subtitle",
        subtitle2: " ",
        labelButton1: "botones.cancelar",
        labelButton2: "botones.continuar",
        cssBoton1: "secundario blue",
        cssBoton2: "primario red",
        showButtons: true
      },
      disableClose: true
    });
    ref.componentInstance.clickBtn2.subscribe(() => {
      this.utilSrv.redireccionarUrlAnterior();
    });
  }
  /**
   * Modal para errores genéricos
   */
  mostrarErrorGenerico() {
    waitUntil(() => this.utilSrv.existeElemento(this.sessionSrv.errorObject), { timeout: 120 * 1000 });
    const code: string = this.sessionSrv.errorObject?.code?.toString() ?? "500";
    if (this.utilSrv.compararValores(code.charAt(0), '4')) {
      this.dialog.open(DecisionModalComponent, {
        data: {
          urlImagen: "./assetsArq/img/error.svg",
          title: "modalError.title",
          subtitle1: "modalError.subtitle",
          subtitle2: "modalError.subtitle2",
          showButtons: false,
          showIconClose: true
        },
        disableClose: true
      });
    }
  }
  /**Abre y devuelve la referencia del modal
   * de aviso de expiracion de sesión   *
   * @returns
   */
  abrirModalExpiracionSesion(): MatDialogRef<DecisionModalComponent, any> {
    return this.dialog.open(DecisionModalComponent, {
      data: {
        urlImagen: "./assetsArq/img/session/warning_session_expired.svg",
        title: "session.expired.modal.title",
        subtitle1: "session.expired.modal.inactividadSubtitle",
        subtitle2: "session.expired.modal.subtitle2",
        labelButton1: "botones.cerrarSesion",
        labelButton2: "botones.continuar",
        cssBoton1: "secundario blue",
        cssBoton2: "primario blue",
        showButtons: true
      },
      disableClose: true
    });
  }
}
