import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LevelType } from '../../enum/levelType.enum';
import { LogTrace } from '../../models/logTrace';
import { ErrorService } from '../errors/error.service';
import { SessionService } from '../session/session.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Servicio de inserción de logs en ELK
 */
export class LogService {
  /**
   * Nombre del api
   */
  apiName!: string;
  /**
   * método constructor
   * @param loggingSrv servicio inserción logs a ELK
   * @param usuarioSrv servicio para recuperar datos del usuario
   */
  constructor(
    private sessionSrv: SessionService,
    private http: HttpClient,
    private errorService: ErrorService) {
      this.apiName = '/ProcesoVentasLogFront/v1.0/';
     }
  /**
 * Método que
 * @param log traza de log generada desde la aplicacion
 * @param apiUrl url de la api que puede ser null
 */
  public initializeSaveLog(log: LogTrace): void {
    const subs$ = this.insertIndex(log).subscribe({
      complete() { subs$.unsubscribe(); }
    });
  }
  /**
   * Método para insertar el indice de ELK
   * @param trace traza de log generada
   * @param api nombre api
   * @returns observable tipo any con lo que devuelve el servicio
   */
  private insertIndex(trace: LogTrace): Observable<any> {
    const headers = new HttpHeaders({
      traceid: trace.traceid ?? ''
    });
    const url = this.sessionSrv.config.apiConf.apiUrl + this.apiName +'EnviarLog';
    return this.http.post<any>(url, trace, { headers });
  }
  /**
   * Generacion de un objeto base
   * de tipo logtrace
   * @returns
   */
  public generateEmptyLogTrace(): LogTrace {
    return {
      timestamp: new Date().toISOString(),
      level: LevelType.info,
      Service: "",
      Environment: "",
      applicationid: "",
      idVentana: "",
      idControl: "",
      idEvent: "",
      Domain: "",
      idUsuario: "",
      descripcion: "",
      message: "",
      idCapa: "frontend"
    } as LogTrace;
  }

  /**
   * Generación objeto log trace basico
   * @returns LogTrace
   */
  private getBasicLogTrace(usuario: string): LogTrace {
    const log = this.generateEmptyLogTrace();
    log.idVentana = this.sessionSrv.urlActual;
    log.applicationid = this.sessionSrv.getLibConfig().applicationName;
    log.Environment = this.sessionSrv.config.env != null ? this.sessionSrv.config.env.toUpperCase() : "";
    log.idUsuario = usuario || '';
    return log;
  }
  /**
   * Método genérico para insertar logs de las acciones que va haciendo el usuario
   * Se llama desde todos los componentes
   * @param traceId
   */
  public insertLog(traceId: string, idControl: string, idEvent: string, level: LevelType, msg: string, usuario: string) {
    const log = this.getBasicLogTrace(usuario);
    log.level = level;
    log.idControl = idControl;
    log.idEvent = idEvent;
    log.message = msg;
    log.traceid = traceId;
    this.initializeSaveLog(log);
  }
  /**
   * Inserción log
   * error peticion http
   * desde interceptor
   * @param req
   * @param error
   */
  public insertHttpLogError(req: HttpRequest<any>, error: any): void {
    const log: LogTrace = this.generateEmptyLogTrace();
    log.applicationid = req.headers.get('applicationid')!;
    log.Environment = this.sessionSrv.config.env?.toUpperCase() ?? '';
    log.idUsuario = this.sessionSrv.getCurrentUserMask() ?? this.sessionSrv.getCurrentUser();
    log.level = LevelType.error;
    log.idVentana = window.location.href;
    log.idControl = req.url.split('?')[0];
    log.idEvent = 'intercept HttpErrorHandler Core';
    log.message = error.message ?? error?.error?.devMsg;
    log.traceid = req.headers.get('traceid')!;
    this.initializeSaveLog(log);
    this.errorService.showMsgError(req, error, this.sessionSrv.getCurrentUser());

  }
}
