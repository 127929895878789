import { Injectable } from '@angular/core';
import { AES, enc } from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  /**
   * Método para cifrar una cadena
   * de texto por AES
   * @param dato cadena a cifrar
   * @param clave de cifrado
   * @returns cadena cifrada
   */
  public cifrar(dato: string, clave: string): string {
    return AES.encrypt(dato, clave).toString();
  }
  /**
   * Método para descifrar una cadena
   * de texto por AES
   * @param dato cadena a descifrar
   * @param clave de cifrado
   * @returns cadena descifrada
   */
  public descifrar(txtCifrado: string, clave: string) {
    const bytes = AES.decrypt(txtCifrado, clave);
    return bytes.toString(enc.Utf8);
  }
}
