import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Pagina } from 'src/app/core/enums/pagina.enum';
import { AsistenteService } from 'src/app/core/providers/asistente.service';
import { UserServiceService } from 'src/app/core/providers/user-service.service';
import { LevelType, LogService, UtilsService } from '@mutual-lib/frontend';
import { SessionService } from '@mutual-lib/frontend';


@Component({
  selector: 'webcam-desktop',
  templateUrl: './webcam-desktop.component.html',
  styleUrls: ['./webcam-desktop.component.scss']
})
export class WebcamDesktopComponent implements OnInit {
  @ViewChild("video")
  public video: ElementRef;

  @ViewChild("canvas")
  public canvas: ElementRef;
  /*Variable que guarda el base64 de la imagen*/
  captura: string = "";
  /*Variable que controla si
  una imagen ha sido ya capturada*/
  isCaptured: boolean;
  /*Variable que indica
  si es el anverso o reverso del
  documento */
  dniCara: string;
  /**
  * Numero de la pagina donde estamos
  */
  pagina = Pagina;
  /**
   * Tipo de nivel para los logs
   * del ELK
   */
  levelType: LevelType;
  /**
   * Mensaje que se pone en los logs
   * del ELK
   */
  msgLog: string;
  /**
   * Variable booleana
   * para habilitar o deshabilitar
   * el boton de capturar imagen
   * si este no dispone de una webcam
   */
  noWebcam!: boolean;

  constructor(
    public userSrv: UserServiceService,
    public utilSrv: UtilsService,
    public asistenteSrv: AsistenteService,
    public sessionSrv: SessionService,
    public logSrv: LogService) {
  }

  /**
   * Se detecta si hay algún
   * dispositivo de vídeo disponible
   * y se acciona la cámara
   */
  async ngOnInit() {
    this.sessionSrv.showSpinner = true;
    this.dniCara = this.asistenteSrv.dniCaraSrv;
    await this.examinarDispositivos();
    this.sessionSrv.showSpinner = false;
  }

  /**
   * Método que examina
   * si el dispositivo
   * tiene webcam o no
   */
  public async examinarDispositivos() {
    const traceId = this.utilSrv.generateTraceId();
    const devices = await navigator.mediaDevices.enumerateDevices();
    const hasVideoDevice = devices.some(device => this.utilSrv.compararValores(device.kind, "videoinput"));
    if (hasVideoDevice) {
      this.noWebcam = false;
      this.levelType = LevelType.info;
      this.msgLog = "Videoinput";
      this.asistenteSrv.closeAllMediaStream(hasVideoDevice);
      this.video.nativeElement.srcObject = await navigator.mediaDevices.getUserMedia({ video: hasVideoDevice });
      this.video.nativeElement.play();
    } else {
      this.noWebcam = true;
      this.levelType = LevelType.error;
      this.msgLog = "NoVideoinput";
    }
    this.logSrv.insertLog(traceId, 'Formulario', 'examinarDispositivos', this.levelType, this.msgLog, 'Usuario');
  }

  /**
   * Método que guarda la imagen en un canvas
   * y la convierte a formato png
   */
  capture() {
    this.drawImageToCanvas(this.video.nativeElement);
    this.captura = this.canvas.nativeElement.toDataURL("image/png");
    this.isCaptured = true;
  }
  /**
   * Método que borra el canvas
   * y activa de nuevo el vídeo para
   * poder hacer
   * otra captura
   */
  removeCurrent() {
    const context = this.canvas.nativeElement.getContext('2d');
    context.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    this.isCaptured = false;
  }
  /**
   * Método que pinta
   * la imagen capturada
   * en un canvas para que sea
   * visible para el usuario
   * @param image
   */
  drawImageToCanvas(image: HTMLImageElement) {
    this.canvas.nativeElement
      .getContext("2d")
      .drawImage(image, 0, 0, 400, 300);
  }
  /**
   * Método que guarda
   * el base64 correspondiente
   * al anverso o reverso del
   * documento en el servicio
   */
  guardadoImagen() {
    this.video.nativeElement.pause();
    this.asistenteSrv.guardadoDniWebcam(this.captura, this.dniCara);
    this.closeModalWebcam();
  }

  /**
   * Método que sirve
   * para cerrar el modal
   * de la webcam
   */
  closeModalWebcam() {
    this.asistenteSrv.showWebcam = false;
    this.asistenteSrv.showCaptura = true;
    if (!this.utilSrv.compararValores(this.asistenteSrv.dniBase64['rev'], "")) {
      this.asistenteSrv.posDNI = 2;
      this.asistenteSrv.isCompletedRev = true;
      this.asistenteSrv.showCaptura = false;
      this.asistenteSrv.pasoInputs();
    }
    else {
      this.asistenteSrv.isCompletedAnv = true;
      this.asistenteSrv.dniCaraSrv = "rev";
      this.asistenteSrv.posDNI = 1;
    }
  }
}
