import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';



@Component({
  selector: 'date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
})
export class DateInputComponent {
  /*FormControl del input*/
  @Input() control: UntypedFormControl;
  /*Etiqueta del campo
  del input*/
  @Input() controlLabel: string;
}
