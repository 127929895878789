import { Injectable, inject } from '@angular/core';
import { UserServiceService } from './user-service.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LevelType, LogService, UtilsService } from '@mutual-lib/frontend';
import { Title } from '@angular/platform-browser';
import { CamposDesplegables } from '../../core/models/CamposDesplegables';
import { Subscription, lastValueFrom } from 'rxjs';
import { Generic } from '../models/Generic';

@Injectable({
  providedIn: 'root'
})
export class UserValidatorService {
  public userSrv: UserServiceService;
  public router: Router;
  public translate: TranslateService;
  public utilSrv: UtilsService;
  public logSrv: LogService;
  public titleService: Title;

  public imgFolder!: string;
  public mvlDesktop!: string;
  public medicorasseValue!: string;
  public smallImage!: string;
  public mainImage!: string;
  public language!: string;
  public showString: boolean = false;
  public selectores!: any;
  public search!: any;
  /*Variable que guarda los
  generos del selector */
  public selectorGenero: any = [];
  /*Variable que guarda los
  años de residencia del selector */
  public selectorResidencia: any = [];
  /*Variable que guarda los
  idiomas del selector */
  public selectorIdioma: any = [];
  public modalTrad!: any;
  public formInputs!: any;
  public provincias: Generic[];
  public originalesProvincias: Generic[];
  public colegis: Generic[];
  public originalesColegis: Generic[];
  public especialitats: Generic[];
  public originalesEspecialitats: Generic[];
  public hospitals: Generic[];
  public originalesHospitals: Generic[];
  /**
   * Varible que guarda la subscripcion
   * para despues poder desubscribirse
   */
  camposDesplegablesSubscription!: Subscription;


  constructor() {
    this.userSrv = inject(UserServiceService);
    this.router = inject(Router);
    this.translate = inject(TranslateService);
    this.utilSrv = inject(UtilsService);
    this.logSrv = inject(LogService);
    this.titleService = inject(Title);
  }

  /**
   * Método que revisa el tipo de
   * navegador para usar
   * las imágenes de móvil o
   * desktop
   */
  public checkNavigator(): void {
    const ua = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      this.imgFolder = "assets/img/Mvl/";
      this.userSrv.mobile = true;
      this.mvlDesktop = "Mvl";
    } else {
      this.imgFolder = "assets/img/Desktop/";
      this.userSrv.mobile = false;
      this.mvlDesktop = "Desktop";
    }
  }

  /**
   * Método que revisa
   * el idioma introducido
   * en la url para usar
   * las imágenes
   * correspondientes
   */
  public checkUrl(): void {
    const splittedUrl = this.router.url.split("/", 4);
    this.translate.use(splittedUrl[1]);
    if (this.utilSrv.compararValores(splittedUrl[3], "medicorasse")) {
      this.medicorasseValue = "1";
    } else {
      this.medicorasseValue = "0";
    }
    this.showString = this.utilSrv.compararValores(this.medicorasseValue, "1") ? !this.showString : this.showString;
    const isCa = this.utilSrv.compararValores(splittedUrl[1], 'ca');
    isCa ? this.titleService.setTitle('Formulari Mutualista Jove') : this.titleService.setTitle('Formulario Mutualista Joven');
    isCa ? this.setImageLang(0) : this.setImageLang(1);
  }

  /**
   * Método que escoge
   * las imágenes en
   * función del idioma
   */
  private setImageLang(position: number) {
    const smallImageArray = ["assets/img/RiscMJ2021_CAT.png", "assets/img/RiscMJ2021_ESP.png"];
    const languageArray = ["1", "2"];
    const mainImageArray = [this.setMainImageCa(), this.setMainImageEsp()];
    this.smallImage = smallImageArray[position];
    this.language = languageArray[position];
    this.mainImage = mainImageArray[position];
  }

  /**
   * Método que selecciona la imagen
   * para la web de MM o Medicorasse
   * en español
   */
  private setMainImageEsp(): string {
    const mainImageArray = [this.imgFolder + "MJ_MM_ES.png", this.imgFolder + "MJ_Medicorasse_ES.png"];
    return mainImageArray[Number(this.medicorasseValue)];
  }

  /**
  * Método que selecciona la imagen
  * para la web de MM o Medicorasse
  * en catalán
  */
  private setMainImageCa(): string {
    const mainImageArray = [this.imgFolder + "MJ_MM_CAT.png", this.imgFolder + "MJ_Medicorasse_CAT.png"];
    return mainImageArray[Number(this.medicorasseValue)];
  }

  /**
   * Método que guarda las traducciones
   * de los desplegables
   */
  public async generarTraducciones() {
    this.selectores = await lastValueFrom(this.translate.get('selectores'));
    this.search = await lastValueFrom(this.translate.get('search'));
    this.selectorResidencia = Object.entries(this.selectores.residencia).map(([key, value]) => ({
      label: value,
      value: key,
    }));
    this.selectorGenero = [
      { "label": this.selectores.genero.generoM, "value": "1" },
      { "label": this.selectores.genero.generoF, "value": "0" }
    ];
    this.selectorIdioma = [
      { "label": this.selectores.idioma.cat, "value": "1" },
      { "label": this.selectores.idioma.es, "value": "2" }
    ];
    this.modalTrad = await lastValueFrom(this.translate.get('modal'));
    this.formInputs = await lastValueFrom(this.translate.get('formInputs'));
  }

  /**
   * Llamada a la API de
   * campos Desplegables para
   * poder asignar los valores
   * a los distintos selectores
   */
  public async camposDesplegables(traceId: string) {
    this.logSrv.insertLog(traceId, 'Formulario', 'camposDesplegables', LevelType.info, 'Llamada a campos desplegables', 'Usuario');
    this.camposDesplegablesSubscription = this.userSrv.camposDesplegables(this.language, traceId).subscribe((obj: CamposDesplegables) => {
      this.originalesProvincias = this.provincias = this.sortArray(obj.Provincies);
      this.originalesColegis = this.colegis = this.sortArray(obj.Colegis);
      this.originalesEspecialitats = this.especialitats = this.sortArray(obj.Especialitats);
      this.originalesHospitals = this.hospitals = this.sortArray(obj.Hospitals);
    });
  }

  /**
   * Ordena los arrays de camps desplegables
   * en orden alfabético
   * @param array
   */
  sortArray(array: Generic[]): Generic[] {
    return array.sort((a: Generic, b: Generic) => (a.Nom > b.Nom) ? 1 : -1);
  }

}
