import { Component,  OnInit, inject } from '@angular/core';
import { UserServiceService } from 'src/app/core/providers/user-service.service';
import compress from 'compress-base64';
import { lastValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'src/app/core/providers/modal.service';
import { LevelType, LogService, SessionService, UtilsService } from '@mutual-lib/frontend';
import { AsistenteService } from 'src/app/core/providers/asistente.service';
import { FicheroService, Fichero } from '@mutual-lib/frontend';


@Component({
  selector: 'captura',
  templateUrl: './captura.component.html',
  styleUrls: ['./captura.component.scss']
})
export class CapturaComponent implements OnInit {
  public translate: TranslateService;
  public modalSrv: ModalService;
  public userSrv: UserServiceService;
  public asistenteSrv: AsistenteService;
  public ficheroSrv: FicheroService;
  public logSrv: LogService;
  public utilSrv: UtilsService;
  public sessionSrv: SessionService;
  public dniCara!: string;
  public modalTrad!: any;
  public arrayExtensiones: Array<Fichero> = [Fichero.PDF, Fichero.JPG, Fichero.JPEG, Fichero.PNG];

  constructor() {
    this.translate = inject(TranslateService);
    this.modalSrv = inject(ModalService);
    this.userSrv = inject(UserServiceService);
    this.asistenteSrv = inject(AsistenteService);
    this.ficheroSrv = inject(FicheroService);
    this.logSrv = inject(LogService);
    this.utilSrv = inject(UtilsService);
    this.sessionSrv = inject(SessionService);
  }

  async ngOnInit() {
    this.dniCara = this.asistenteSrv.dniCaraSrv;
    this.modalTrad = await lastValueFrom(this.translate.get('modal'));
  }

  /**
   * Método que guarda el valor del base64 de la imagen
   * y revisa que no supere los 5MB,
   * que el nombre no contenga caracteres especiales
   * y que la longitud del nombre sea adecuada
   * @param evento
   * @param variable La variable se corresponde con
   * 'dnianv' o 'dnirev'
   *
   */
  onselectDoc(documento: any) {
    const traceId = this.utilSrv.generateTraceId();
    if (this.utilSrv.compararValores(documento.target.files.length, 0)) {
      this.cleanImage(false, traceId);
      return;
    } else if (this.checkMaxfileName(documento, traceId) && this.checkInvalidCharacterFileName(documento, traceId) && this.checkDocumentSize(documento, traceId)) {
      this.readImage(documento, traceId);
    }
  }

  /**
   * Metodo que valida que el nombre del documento
   * no supere los 128 caracteres.
   */
  public checkMaxfileName(documento: any, traceId: string) {
    let success = true;
    if (this.ficheroSrv.checkMaxFileNameLength(documento, 128)) {
      this.logSrv.insertLog(traceId, 'captura', 'mostrarModalError', LevelType.info, 'Error en longitud nombre documento', 'Usuario');
      this.modalSrv.showDialog(this.modalTrad.errorFormDocNameSize, "error");
      success = false;
    }
    return success;
  }
  /**
   * Metodo que valida que el nombre del documento
   * no contenga caracteres invalidos.
   */
  public checkInvalidCharacterFileName(documento: any, traceId: string): boolean {
    let success = true;
    if (this.ficheroSrv.checkCaracteresInvalidos(documento)) {
      this.logSrv.insertLog(traceId, 'captura', 'mostrarModalError', LevelType.info, 'Error en carácteres nombre documento', 'Usuario');
      this.modalSrv.showDialog(this.modalTrad.errorFormDocNameInvalidChar, "error");
      success = false;
    }
    return success;
  }

  /**
   * Metodo que abre el modal
   * cuando se superan los
   * 5MB
   */
  public checkDocumentSize(documento: any, traceId: string): boolean {
    const sizeAllowed = documento.target.files[0].size;
    let success = true;
    if (sizeAllowed > 5242880) {
      this.logSrv.insertLog(traceId, 'captura', 'mostrarModalError', LevelType.info, 'Error en tamaño documento', 'Usuario');
      this.modalSrv.showDialog(this.modalTrad.errorForm5MB, "errorDocu")
      success = false;
    }
    return success;
  }

  /**
   * Función que permite
   * leer el objeto que contiene
   * los datos de la imagen
   * @param evento
   * @param variable La variable se corresponde con
   * 'dnianv' o 'dnirev'
   */
  public readImage(evento: HTMLInputElement & { target: HTMLInputElement }, traceId: string) {
    this.asistenteSrv.dniNombre[this.dniCara] = evento.target.files[0].name;
    this.checkExtension(evento, traceId);
  }

  /**
   * Limpia la variable que
   * guarda la imagen
   * @param variable La variable se corresponde con
   * 'dnianv' o 'dnirev'
   */
  public async checkExtension(evento: HTMLInputElement & { target: HTMLInputElement }, traceId: string) {
    const checking = (await Promise.all([this.ficheroSrv.checkExtension(evento.target.files[0],
      this.arrayExtensiones)
    ]))[0];
    checking ? this.createBase64(evento) : this.cleanImage(true, traceId);
  }

  /**
   * Mètode que comprimi el base64 de las imagenes
   * @param reader
   * @param variable
   * @param evento
   */
  public createBase64(evento: HTMLInputElement & { target: HTMLInputElement; }) {
    const type = evento.target.files[0].type;
    const reader = new FileReader();
    reader.readAsDataURL(evento.target.files[0]);
    reader.onload = async () => {
      if (type.includes('image') && evento.target.files[0].size > 2097152) {
        await compress(reader.result as string, {
          width: 600,
          type: type,
          max: 200,
          min: 20,
        }).then(result => {
          this.asistenteSrv.dniBase64[this.dniCara] = result;
          this.comprobarCaraDni();
        });
      } else {
        this.asistenteSrv.dniBase64[this.dniCara] = reader.result;
        this.comprobarCaraDni();
      }
    };
  }

  /**
   * Metodo que nos comprueba
   * si la cara reversa del DNI
   * esta con datos
   */
  public comprobarCaraDni() {
    if (!this.utilSrv.compararValores(this.asistenteSrv.dniBase64['rev'], "")) {
      this.asistenteSrv.isCompletedRev = true;
      this.asistenteSrv.showCaptura = false;
      this.asistenteSrv.pasoInputs();
    } else {
      this.asistenteSrv.isCompletedAnv = true;
      this.asistenteSrv.dniCaraSrv = "rev";
      this.dniCara = "rev";
    }
    this.asistenteSrv.posDNI += 1;
  }

  /**
   * Metodo que nos
   * limpia las variables
   * de las imagenes
   * @param variable dniAnv | dniRev
   * @param size si el tamaño del fichero supera los 5 megabites
   */
  cleanImage(errorExtension: boolean, traceId: string) {
    this.asistenteSrv.dniBase64[this.dniCara] = "";
    this.asistenteSrv.dniNombre[this.dniCara] = "";
    this.asistenteSrv.dniCaraSrv = this.dniCara;
    if (errorExtension) {
      this.logSrv.insertLog(traceId, 'captura', 'mostrarModalError', LevelType.info, 'Error en la extension del documento', 'Usuario');
      this.modalSrv.showDialog(this.modalTrad.errorFormExtension, "error");
    }
  }

  /**
   * Método que cambia las variables
   * del captura a false y
   * webcam a true cuando abrimos la
   * webcam
   */
  abrirWebcam() {
    this.asistenteSrv.showCaptura = false;
    this.asistenteSrv.showWebcam = true;
  }

}
