<!-- titulo -->
<div class="flex row space-between cabecera">
  <h1 mat-dialog-title class="title">
    {{'modalErrorTecnico.title' | translate}}
  </h1>
  <button mat-icon-button aria-label="Botón de cerrar" mat-dialog-close [disableRipple]="true">
    <mat-icon>close</mat-icon>
  </button>
</div>
<!-- texto informativo -->
<div mat-dialog-content class="main-container">
  <div class="flex row space-between infoCliente">
    <img [src]="'./assetsArq/img/fonendo.svg'" alt="fonendo imagen">
    <div class="flex column msg">
      <p class="subtitle">{{'modalErrorTecnico.generico1' | translate}}</p>
      <p class="subtitle">{{'modalErrorTecnico.generico2' | translate}}</p>
      <p class="subtitle">{{'modalErrorTecnico.generico3' | translate}}</p>
    </div>
  </div>
  <mat-expansion-panel class="selector-error-tecnico" hideToggle="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{'modalErrorTecnico.button' | translate}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-divider></mat-divider>
    <div class="info">
      <p><span class="label">{{'modalErrorTecnico.fechaHora' | translate}}</span> {{data.fecha | date: 'dd/MM/yyyy, HH:mm'}}</p>
      <p><span class="label">{{'modalErrorTecnico.usuario' | translate}}</span> {{data.usuario}}</p>
      <p><span class="label">{{'modalErrorTecnico.ip' | translate}}</span> {{data.ip}}</p>
      <p><span class="label">{{'modalErrorTecnico.idTrazabilidad' | translate}}</span> {{data.traceid}}</p>
      <p><span class="label">{{'modalErrorTecnico.url' | translate}} </span> {{data.url}}</p>
      <p><span class="label">{{'modalErrorTecnico.navegador' | translate }}</span> {{data.navegador}}</p>
      <p><span class="label">{{'modalErrorTecnico.errorTecnico' | translate}}</span> {{data.mensajeTecnico}}</p>
    </div>
  </mat-expansion-panel>
</div>
