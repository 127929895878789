import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisibilityControlDirective } from './directives/visibility-control.directive';
import { UserActivityDirective } from './directives/userActivity.directive';

const directives = [
  UserActivityDirective,
  VisibilityControlDirective
];

/**
 * Modulo core donde tenemos los pipe y servicios comunes
 */
@NgModule({
  declarations: [
    directives
  ],
  imports: [
    CommonModule
  ],
  exports: [
    directives
  ]
})
/**
 * Módulo core que incluye
 * enums, interceptores, servicios...
 */
export class CoreModule { }
