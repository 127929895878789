import { Component, Input, inject, } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { SessionService } from '../../core/providers/session/session.service';

@Component({
  selector: 'mm-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {

  /**
   * color para el componente
   */
  @Input() color?: string = '#006699';
  /**
   * Modo de la barra de progreso
   * Opciones: determinate, indeterminate
   */
  @Input() mode: ProgressSpinnerMode = 'indeterminate';
  /**
   * Permite mostrar u ocultar el texto
   */
  @Input() showTxt: boolean = true;

  /**Servicio utils */
  public sessionSrv!: SessionService;
  /**
   * Método constructor
   * inyección de servicios
   */
  constructor() {
    this.sessionSrv = inject(SessionService);
  }
}
