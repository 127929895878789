export const FicherosHEX: FicheroHex[] = [
  { type: 'PDF', value: '25504446' },
  { type: 'JPG', value: 'FFD8FFE0' },
  { type: 'JPEG', value: 'FFD8FFDB' },
  { type: 'JPEG', value: 'FFD8FFEE' },
  { type: 'JPEG', value: 'FFD8FFE1' },
  { type: 'PNG', value: '89504E47' }
];
/**
 * Valor hexadecimal para cada tipo
 * de fichero
 */
export class FicheroHex {
  type: string | undefined;
  value: string | undefined;
}
