<div class="input-form">
  <mat-form-field appearance="fill">
    <mat-label class="new-label">{{
      controlLabel
      }}</mat-label>
    <mat-select [formControl]="control">
      <mat-option *ngFor="let item of list" [value]="item.value">{{ item.label }}</mat-option>
    </mat-select>
  </mat-form-field>
  <div>
    <mat-error *ngIf="
          control.errors?.['required'] &&
          control.touched
        ">
      {{ "app.fieldRequired" | translate }}</mat-error>
  </div>
</div>
