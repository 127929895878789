import { Injectable, inject } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { HttpHeaders } from '@angular/common/http';
import { isNil } from 'lodash';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { SessionService } from '../session/session.service';
import waitUntil from 'async-wait-until';

@Injectable({
  providedIn: 'root'
})

export class UtilsService {

  private datepipe!: DatePipe;
  private router!: Router;
  private sessionSrv!: SessionService;
  /**
   * Constructor
   * inyeccion servicios
   */
  constructor() {
    this.datepipe = inject(DatePipe);
    this.router = inject(Router);
    this.sessionSrv = inject(SessionService);
  }
  /**
     * Devuelve true o false
     * si los valores son iguales
     */
  public compararValores(val1: any, val2: any): boolean {
    return val1 === val2;
  }
  /**
   * Devuelve true o false
   * si los objetos son iguales
   */
  public compararObjetos(obj1: any, obj2: any): boolean {
    return Object.entries(obj1).toString() === Object.entries(obj2).toString();
  }
  /**
   * Devuelve true si el array tiene elementos
   * @param array aray de cualquier tipo
   * @returns true o false
   */
  public validarLengthArray(array: any[]): boolean {
    return this.existeElemento(array) && (array.length > 0);
  }


  /**
   * Valida si el elemento
   * es distinto de null
   * y undefined
   * @param elem elemento de cualquier tipo
   * @returns true si existe
   */
  public existeElemento(elem: any): boolean {
    return !isNil(elem);
  }

  /**
   * Generación traceid para trazas log
   * @returns string
   */
  public generateTraceId(): string {
    return uuidv4();
  }

  /**
  * generacion headers con traceid
  * @param traceId string-identificador
  * @returns HttpHeaders
  */
  public setTraceIdHeader(traceId: string): HttpHeaders {
    return new HttpHeaders({
      'traceid': traceId
    });
  }
  /**
  * Calcula los minutos que han pasadao
  * entre una fecha determinada y la fecha actual
  * @returns numero - minutos
  */
  public getMinLeft(date: Date): number {
    const fechaActual = new Date();
    const minutesLeft = this.getMinDiff(fechaActual, date);
    return minutesLeft;
  }
  /**
   * Calcula los minutos restantes
   * en base a dos fechas
   * @param startDate fecha inicio
   * @param endDate fecha fin
   * @returns minutos
   */
  public getMinDiff(startDate: Date, endDate: Date) {
    const msInMinute = 60 * 1000;
    return Math.round(
      Math.abs(endDate.getTime() - startDate.getTime()) / msInMinute
    );
  }
  /**
   * Método que devuelve la fecha
   * parseada en función del formato
   * @param fecha
   * @returns
   */
  public parseDate(fecha: Date, formato: string): string {
    const newDate = new Date(fecha);
    return this.datepipe.transform(newDate, formato)!;
  }
  /**
   * Valida que no sea la primera pantalla donde carga keycloak
   * y navega a ella
   */
  public redireccionarUrlAnterior() {
    if (!this.compararValores(this.sessionSrv.ultimaUrlVisitada, '/')) {
      this.router.navigate([this.sessionSrv.ultimaUrlVisitada]);
    }
  }
  /**
   * Redirecciona a la ruta recibida por parámetro
  */
  public redireccionar(ruta: string) {
    this.router.navigate(['/' + this.sessionSrv.idiomaActual + '/' + ruta]);
  }
  /**
   * Método que se llama desde
   * los componentes del menu para
   * marcar la ruta activa por ventana
   * @param path nombre del path
   * @returns true po false
   */
  isRouteActive(path: string) {
    return this.router.url.includes(path);
  }
  /**
  * Método que evita que se lance un evento
  * @param event
  */
  detenerPropagacion(event: any) {
    event.stopPropagation();
  }

  /**
   * Método que pone el foco en un
   * elemento del html, identificado
   * por su id
   */
  async focusResults(idElemento: string) {
    await waitUntil(() => this.existeElemento(document.getElementById(idElemento)));
    document.getElementById(idElemento)!.scrollIntoView({ behavior: 'smooth' });
    this.sessionSrv.showSpinner = false;
  }
}
