import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import waitUntil from 'async-wait-until';
import { ProfileService } from '../providers/security/profile.service';
import { SessionService } from '../providers/session/session.service';
import { UtilsService } from '../providers/utils/utils.service';

export const RouteGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const profileSrv = inject(ProfileService);
  const utilSrv = inject(UtilsService);
  const sessionSrv = inject(SessionService);
  /**
   * Espera hasta que el SessionGuard termine de validar
   * la sesión
   */
  await waitUntil(() => utilSrv.existeElemento(sessionSrv.loginOk), { timeout: 120 * 1000 });
  await profileSrv.loadRutas(utilSrv.generateTraceId());
  const permitir = profileSrv.isRutaPermitida(state.url);
  if (!permitir) {
    utilSrv.redireccionarUrlAnterior();
  }
  return permitir;
}
