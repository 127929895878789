<div [class.fix-width-height]="!asistenteSrv.showWebcam" [class.responsive]="sessionSrv.responsive">
  <div class="flex end">
    <button class="close" (click)="closeDialog()">
      <!-- icono cerrar -->
      <img [src]="'./assets/img/icons/close_popup.svg'" alt="{{ 'modal.errorForm.altIcono' | translate }}">
    </button>
  </div>
  <div class="flex column justify-center">
    <!-- stepperNumber -->
    <mat-stepper class="customStepper" [selectedIndex]=asistenteSrv.posDNI>
      <!-- Pedir dni anverso -->
      <mat-step [editable]="false" [completed]="asistenteSrv.isCompletedAnv">
        <div class="flex column justify-center" *ngIf="!asistenteSrv.showWebcam">
          <img [src]="'./assets/img/icons/icono-dni-anverso.svg'" alt="{{ 'asistente.anvIcono' | translate }}">
          <p class="txtTitle">{{ "asistente.anvTitle" | translate }}</p>
          <p class="txtInfo">{{ "asistente.anvInfo" | translate }}</p>
        </div>
        <captura *ngIf="asistenteSrv.showCaptura"></captura>
        <webcam-desktop *ngIf="asistenteSrv.showWebcam"></webcam-desktop>
        <div class="mt-2per">
          <button class="form-button blueButton blueLetter ma" [ngClass]="sessionSrv.responsive && !userSrv.tabletMode ? 'w-70' : 'w-35'"
            [class.w-50]="userSrv.tabletMode" (click)="closeDialog()">
            {{'asistente.cancelar' | translate }}
          </button>
        </div>
      </mat-step>
      <!-- Pedir dni reverso -->
      <mat-step [editable]="false" [completed]="asistenteSrv.isCompletedRev">
        <div class="flex column justify-center" *ngIf="!asistenteSrv.showWebcam">
          <img [src]="'./assets/img/icons/icono-dni-reverso.svg'" alt="{{ 'asistente.revIcono' | translate }}">
          <p class="txtTitle">{{ "asistente.revTitle" | translate }}</p>
          <p class="txtInfo">{{ "asistente.revInfo" | translate }}</p>
        </div>
        <captura *ngIf="asistenteSrv.showCaptura"></captura>
        <webcam-desktop *ngIf="asistenteSrv.showWebcam"></webcam-desktop>
        <div class="mt-2per">
          <button class="form-button blueButton blueLetter ma" [ngClass]="sessionSrv.responsive && !userSrv.tabletMode ? 'w-70' : 'w-35'"
            [class.w-50]="userSrv.tabletMode" (click)="closeDialog()">
            {{'asistente.cancelar' | translate }}
          </button>
        </div>
      </mat-step>
      <!-- Validar/Llenar datos del dni -->
      <mat-step>
        <div *ngIf="asistenteSrv.showInputs" class="flex justify-center column datosDocumento">
          <datos-documento [formInputs]="userValidator.formInputs"></datos-documento>
          <button class="form-button blueButton blueLetter ma" [ngClass]="sessionSrv.responsive && !userSrv.tabletMode ? 'w-70' : 'w-35'"
            [class.w-50]="userSrv.tabletMode" (click)="closeDialog()">
            {{'asistente.cancelar' | translate }}
          </button>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</div>
