<!-- titulo -->
<div class="flex row space-between cabecera">
  <h1 mat-dialog-title class="title">
    {{'modalErrorPerfilado.title' | translate}}
  </h1>
  <button mat-icon-button aria-label="Botón de cerrar" mat-dialog-close [disableRipple]="true" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<!-- texto informativo -->
<div mat-dialog-content class="main-container">
  <div class="flex row space-between infoCliente">
    <div class="flex column msg">
      <p class="subtitle">{{'modalErrorPerfilado.mensaje' | translate}}</p>
    </div>
  </div>
</div>
