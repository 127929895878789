<div class="input-form">
  <mat-form-field appearance="fill">
    <mat-label class="new-label">{{
      controlLabel
      }}</mat-label>
    <input matInput [matDatepicker]="picker" [formControl]="control" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <div>
    <mat-error *ngIf="
          control.errors?.['required'] &&
          control.touched 
        ">
      {{ "app.fieldRequired" | translate }}</mat-error>
  </div>
</div>
