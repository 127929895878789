import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'mm-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.scss']
})
/**
 * Pop up caducidad
 * sesion
 */
export class ModalErrorComponent {
  /**
   * Método constructor
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: any = {}) { }
}
