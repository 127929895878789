import { Injectable, inject } from '@angular/core';
import { UtilsService } from '@mutual-lib/frontend';


@Injectable({
  providedIn: 'root'
})
export class DniValidatorService {
  validarLetras: string = 'TRWAGMYFPDXBNJZSQVHLCKE';
  public utilsSrv: UtilsService;

  constructor() {
    this.utilsSrv = inject(UtilsService);
  }

  public validator(control: string): boolean {
    let result = false;
    if(this.dniValidador(control) || this.nieValidator(control)){
      result = true;
    }
    return result;
  }

  /**
   * Metodo que valida
   * si el DNI tiene el formato correcto
   * y la letra correcta
   * @param dni
   */
  public dniValidador(dni: string): boolean{
    const patronDNI = /^[0-9]{8}[A-za-z]$/i;
    let result = patronDNI.test(dni);
    if(result){
      const dniNumero = dni.substring(0,8);
      const dniLetra = dni.charAt(dni.length - 1).toUpperCase();
      const letraCalculada = this.validarLetras.charAt(Number(dniNumero) % 23);
      result = this.utilsSrv.compararValores(dniLetra,letraCalculada);
    }
    return result;
  }

  /**
   * Metodo que valida
   * si el NIE tinen el formato correcto
   * y la letra correcta
   * @param nie
   */
  public nieValidator(nie: string): boolean{
    const patronNIE = /^[XYZxyz][0-9]{7}[A-Za-z]$/;
    let result = patronNIE.test(nie);
    if(result){
      let nieNumero = nie.substring(0, 8).toUpperCase();
      nieNumero = nieNumero.replace('X','0');
      nieNumero = nieNumero.replace('Y','1');
      nieNumero = nieNumero.replace('Z','2');
      const nieLetra = nie.charAt(8).toUpperCase();
      const letraCalculada = this.validarLetras.charAt(Number(nieNumero) % 23);
      result = this.utilsSrv.compararValores(nieLetra,letraCalculada);
    }
    return result;
  }
}
