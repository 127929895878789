import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mm-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  /**
     * identificador del elemento
     * para el perfilado
     * */
  @Input() id!: string | undefined;
  /**
   * Texto del botón
   */
  @Input() label!: string;
  /**
   * Clase css para aplicar, opciones: primario, secundario red, secundario blue
   * si se quiere deshabilitar añadir clase disabled
   */
  @Input() cssClass!: string;
  /**
   * Muestra u oculta el boón
   */
  @Input() show: boolean = true;
  /**
   * Bloquea o habilita el click en el botón
   */
  @Input() disabledButton!: boolean;
  /**
   * Event emitter
   */
  @Output() notifyClick: EventEmitter<boolean> = new EventEmitter();

  /**
   * Lanza un evento al padre
   * para notificar que se ha clicado
   */
  notificarClick() {
    this.notifyClick.emit(true);
  }
}
