import { FrontendModule } from '@mutual-lib/frontend';
import { HttpBackend, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserFormComponent } from './modules/user-form/user-form.component';
import { ModalDialogComponent } from './shared/modales/modal-dialog/modal-dialog.component';
//intalem les llibreries "npm install --save @angular/material-moment-adapter" i "npm install --save moment"
//i seguidament canviem "MatNativeDateModule" per "MatMomentDateModule" i ja ens agafara be la data
import { DatePipe } from '@angular/common';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { arqLibConf } from 'src/assets/config/arqLibConfig';
import { CapturaComponent } from './modules/asistente-documento/captura/captura.component';
import { CaraDocumentoComponent } from './modules/asistente-documento/cara-documento/cara-documento.component';
import { DatosDocumentoComponent } from './modules/asistente-documento/datos-documento/datos-documento.component';
import { WebcamDesktopComponent } from './modules/asistente-documento/webcam-desktop/webcam-desktop.component';
import { DateInputComponent } from './shared/inputs/date-input/date-input.component';
import { FormInputComponent } from './shared/inputs/form-input/form-input.component';
import { SearchInputComponent } from './shared/inputs/search-input/search-input.component';
import { SelectorInputComponent } from './shared/inputs/selector-input/selector-input.component';
import { ModalAsistenteComponent } from './shared/modales/modal-asistente/modal-asistente.component';
import { MatStepperModule } from '@angular/material/stepper'
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

/**
 * Cargar ruta al fichero de traducciones al servicio de traducción
 */
export function HttpLoaderFactory(_httpBackend: HttpBackend) {
  return new MultiTranslateHttpLoader(_httpBackend,
    [
      { prefix: './assets/i18n/', suffix: '.json' },
      { prefix: './assetsArq/i18n/', suffix: '.json' },
    ]
  );
}

/**
 * Mòdul que te com ha objectiu
 * agrupar un conjunt de components
 * relacionats entre si, on finalment
 * el component sera consumit/cridat des de
 * un altre mòdul de l'aplicacio
 */
@NgModule({
  declarations: [
    AppComponent,
    UserFormComponent,
    ModalDialogComponent,
    WebcamDesktopComponent,
    FormInputComponent,
    DateInputComponent,
    SelectorInputComponent,
    SearchInputComponent,
    CaraDocumentoComponent,
    CapturaComponent,
    DatosDocumentoComponent,
    ModalAsistenteComponent
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      },
      defaultLanguage: 'es',
      useDefaultLang: true
    }),
    FrontendModule.forRoot(arqLibConf),
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatSelectModule,
    MatDialogModule,
    NgxMatSelectSearchModule,
    MatStepperModule,
    MatButtonModule
  ],
  providers: [DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    // { provide: 'env', useValue: environment },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
