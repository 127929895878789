<div class="flex row space-between">
  <p *ngIf="titulo !== undefined" class="titulo">{{titulo | translate}}</p>
  <mm-icon-button *ngIf="mostrarBotonCerrar" [srcIcon]="'./assetsArq/img/icons/close_grey.svg'"
    (notifyClick)="cerrarBusqueda.emit()"></mm-icon-button>
</div>
<div class="flex column gap32">
  <ng-container [ngTemplateOutlet]="formularioTemplate"></ng-container>
  <div class="flex row align-center start gap40">
    <mm-button [id]="" [label]="'botones.buscar'" [cssClass]="cssBotonBuscar" (notifyClick)="iniciarBusqueda.emit()"
      [disabledButton]="disabledBuscar" [style]="'width: 116px;'"></mm-button>
    <mm-icon-button *ngIf="isSomeFilled()" [srcIcon]="'./assetsArq/img/icons/papelera.svg'" [label]="'botones.limpiarBusqueda'"
      [cssClass]="'limpiarBusqueda'" (notifyClick)="formulario.reset();limpiarBusqueda.emit()"></mm-icon-button>
  </div>
</div>
