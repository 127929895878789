import { Injectable } from '@angular/core';
import { Fichero } from '../../enum/Fichero.enum';
import { FicheroHex, FicherosHEX } from '../../enum/ficherosHEX';
import compress from 'compress-base64';
import { compressB64 } from '../../models/compressB64';

@Injectable({
  providedIn: 'root'
})
export class FicheroService {

  /**
   * Método que se utiliza para
   * validar si el fichero adjunto
   * está permitido.
   * @param file documento adjunto
   * @param extensionesPermitidas array con los ficheros permitidos
   * @returns Promise<boolean>
   */
  public async checkExtension(file: File, extensionesPermitidas: Fichero[]) {
    const [magicByte] = await Promise.all([this.getMagicNumber(file, 4)]);
    /**
     * Se busca el elemento que coincida por tipo y valor (magic number)
     */
    const extensionEncontrada = extensionesPermitidas.filter((tipoFichero: Fichero) => {
      return FicherosHEX.find((hex: FicheroHex) => tipoFichero === hex.type && hex.value === magicByte)
    });
    return extensionEncontrada.length > 0;
  }
  /**
   * Método que devuelve el magic number o magic byte
   * del fichero que se ha adjuntado
   * @param file documento
   * @param signatureLength posicion final para slice
   * @returns Promise<string>
   */
  public async getMagicNumber(file: File, signatureLength: number): Promise<string> {
    return file
      .slice(0, signatureLength)
      .arrayBuffer()
      .then((buffer) =>
        Array.from(new Uint8Array(buffer))
          .map((byte) => byte.toString(16).padStart(2, '0'))
          .join('')
          .toUpperCase()
      );
  }

  /**
   * Método que valida si el formato
   * del nombre del fichero adjunto es válido
   * @param documento documento
   * @returns boolean
   */
  public checkCaracteresInvalidos(documento: any): boolean {
    const fileName = documento.target.files[0].name;
    const illegalRe = /[\/\?<>\\:;+`= % \*\|"$:]/g; //Illegal Characters on Various Operating System / ? < > \ : ; + ` = % * | " $
    const controlRe = /[\x00-\x1f\x80-\x9f]/g; // Unicode Control codes C0 0x00-0x1f & C1 (0x80-0x9f)
    const reservedRe = /^\.+$/; //Reserved filenames on Unix-based systems (".", "..")
    const urlcontrol = /^(http|https)/;
    const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;//Reserved filenames in Windows ("CON", "PRN", "AUX", "NUL", "COM1"...

    const pattern = new RegExp(illegalRe.source + "|" + controlRe.source + "|" + reservedRe.source + "|" + urlcontrol.source + "|" + windowsReservedRe.source);
    return fileName?.match(pattern);
  }
  /**
   * Método que comprueba que la
   * longitud del nombre del fichero
   * no supere el límite
   * @param documento documento
   * @param maxLength numero maximo de caracteres
   * @returns boolean
   */
  public checkMaxFileNameLength(documento: any, maxLength: number): boolean{
    const fileNamesize = documento.target.files[0].name?.length || 0;
    return documento && fileNamesize > maxLength;
  }


  /**
   * Método que comprueba que el fichero
   * enviado/recibido no supere
   * el tamaño establecido
   */
  public checkDocumentSize(documento: any, sizeMax: number): boolean {
    const sizeAllowed = documento.target.files[0].size;
    return sizeMax >= sizeAllowed;
  }

  /**
   * Mètode que comprime las imagenes
   * @param reader
   * @param variable
   * @param evento
   */
  public async createBase64(file: File, options: compressB64) {
    let compressImg!: string;
    const type = file.type
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      if (type.includes('image') && file.size > 2097152) {
        await compress(reader.result as string, options).then(result => {
          compressImg = result as string;
        });
      } else {
        compressImg = reader.result as string;
      }
    };
    return compressImg;
  }

}
