import { Component, ElementRef,  OnInit, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AsistenteService } from 'src/app/core/providers/asistente.service';
import { Subscription, lastValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserServiceService } from 'src/app/core/providers/user-service.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { LevelType, LogService, SessionService, UtilsService } from '@mutual-lib/frontend';
import { UserValidatorService } from 'src/app/core/providers/user-validator.service';



@Component({
  selector: 'app-cara-documento',
  templateUrl: './cara-documento.component.html',
  styleUrls: ['./cara-documento.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: true }
  }]
})
export class CaraDocumentoComponent implements OnInit {
  public asistenteSrv: AsistenteService;
  public translateSrv: TranslateService;
  public userSrv: UserServiceService;
  public logSrv: LogService;
  public utilSrv: UtilsService;
  public sessionSrv: SessionService;
  public userValidator: UserValidatorService;

  stream: any;
  video: ElementRef;
  finSubscription!: Subscription;

  constructor(public dialogRef: MatDialogRef<CaraDocumentoComponent>) {
    this.asistenteSrv = inject(AsistenteService);
    this.translateSrv = inject(TranslateService);
    this.userSrv = inject(UserServiceService);
    this.logSrv = inject(LogService);
    this.utilSrv = inject(UtilsService);
    this.sessionSrv = inject(SessionService);
    this.userValidator = inject(UserValidatorService);
  }

  async ngOnInit() {
    this.asistenteSrv.inicializar();
    this.comprobarWebcam();
    this.finSubscription = this.asistenteSrv.finAsistente.subscribe(() => {
      this.dialogRef.close();
    });
  }

  /**
   * Método para
   * desuscribirse de los eventos
   */
  ngOnDestroy() {
    this.finSubscription?.unsubscribe();
    this.asistenteSrv.dniSuscription?.unsubscribe();
  }

  /**
   * Método que pregunta al usuario
   * si da permisos para abrir
   * la cámara o no
   */
  public async comprobarWebcam() {
    const traceId = this.utilSrv.generateTraceId();
    this.asistenteSrv.hasWebcam = true;
    const permissionName = "camera" as PermissionName;
    await navigator.permissions.query({ name: permissionName })
      .then(({ state }) => {
        if (this.utilSrv.compararValores(state, "granted")) {
          this.asistenteSrv.hasWebcam = true;
          this.logSrv.insertLog(traceId, 'Formulario', 'comprobarWebcam', LevelType.info, 'Granted', 'Usuario');
        } else if (this.utilSrv.compararValores(state, "denied")) {
          this.asistenteSrv.hasWebcam = false;
          this.logSrv.insertLog(traceId, 'Formulario', 'comprobarWebcam', LevelType.info, 'Denied', 'Usuario');
        }
      });
  }
  /**
   * Método que
   * sirve para cerrar
   * el modal
   */
  closeDialog() {
    this.dialogRef.close();
    this.asistenteSrv.showWebcam = false;
  }
}
