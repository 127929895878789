<div class="titleWebcam">
  <div *ngIf="asistenteSrv.posDNI == 0; else revBlock">
    {{ "webcam.capturaTitleAnv" | translate }}
  </div>
  <ng-template #revBlock>
    {{ "webcam.capturaTitleRev" | translate }}
  </ng-template>
</div>
<div class="flex column captura">
  <video class="ma" [class.show]="!isCaptured" [class.hide-element]="isCaptured" #video id="video" [width]="400"
    [height]="400" autoplay></video>
  <canvas class="ma" [class.show]="isCaptured" [class.hide-element]="!isCaptured" #canvas id="canvas" [width]="400"
    [height]="300"></canvas>
</div>

<div class="flex justify-center ma" [ngClass]="sessionSrv.responsive && !userSrv.tabletMode ? 'w-70' : 'w-35'"
  [class.w-50]="userSrv.tabletMode"
  *ngIf="asistenteSrv.posDNI == 0; else capturaRevBlock">
  <button class="fullBlueButton" *ngIf="!isCaptured" (click)="capture()" [disabled]="noWebcam">{{ "webcam.capturaAnv" | translate }}</button>
</div>
<ng-template #capturaRevBlock>
  <button class="fullBlueButton flex justify-center ma"
  [ngClass]="sessionSrv.responsive && !userSrv.tabletMode ? 'w-70' : 'w-35'"
  [class.w-50]="userSrv.tabletMode"
    *ngIf="!isCaptured" (click)="capture()">{{ "webcam.capturaRev" | translate }}</button>
</ng-template>

<div class="flex justify-center column mt-2" style="gap:8px">
  <button *ngIf="isCaptured" class="fullBlueButton ma" [ngClass]="sessionSrv.responsive && !userSrv.tabletMode ? 'w-70' : 'w-35'"
  [class.w-50]="userSrv.tabletMode"
    (click)="guardadoImagen()">{{ "webcam.enviar" | translate }}</button>
  <button  *ngIf="isCaptured" class="fullBlueButton ma" [ngClass]="sessionSrv.responsive && !userSrv.tabletMode ? 'w-70' : 'w-35'"
  [class.w-50]="userSrv.tabletMode"
    (click)="removeCurrent()">{{ "webcam.repetir" | translate }}</button>
</div>
