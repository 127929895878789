import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { ModalErrorComponent } from './modals/modal-error/modal-error.component';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerComponent } from './spinner/spinner.component';
import { ModalErrorPerfiladoComponent } from './modals/modal-error-perfilado/modal-error-perfilado.component';
import { ButtonGroupComponent } from './buttons/button-group/button-group.component';
import { LogoComponent } from './logo/logo.component';
import { UserImgComponent } from './user-img/user-img.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { MenuComponent } from './menus/menu/menu.component';
import { IconButtonComponent } from './buttons/icon-button/icon-button.component';
import { UserMenuComponent } from './menus/user-menu/user-menu.component';
import { MaterialModule } from './material.module';
import { MenuSupportComponent } from './menus/menu-support/menu-support.component';
import { DirectAccessComponent } from './direct-access/direct-access.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from './elementos-formularios/input/input.component';
import { ButtonComponent } from './buttons/button/button.component';
import { HttpClientModule } from '@angular/common/http';
import { ActionMessageComponent } from './action-message/action-message.component';
import { SessionMessageComponent } from './session-message/session-message.component';
import { DecisionModalComponent } from './modals/decision-modal/decision-modal.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { TablaSeleccionComponent } from './tables/tabla-seleccion/tabla-seleccion.component';
import { TablaAccionesComponent } from './tables/tabla-acciones/tabla-acciones.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PaginadorComponent } from './paginador/paginador.component';
import { InputTallerComponent } from './elementos-formularios/input-taller/input-taller.component';
import { MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatepickerHeaderComponent } from './elementos-formularios/datepicker-header/datepicker-header.component';
import { StepperComponent } from './stepper/stepper.component';
import { BannerInformativoComponent } from './banner-informativo/banner-informativo.component';
import { BuscadorComponent } from './buscador/buscador.component';
import { MenuAccionesComponent } from './menus/menu-acciones/menu-acciones.component';

/**
 * Etiquetas personalizadas para los días
 * del datepicker
 * */
class CustomDateAdapter extends MomentDateAdapter {
  override getDayOfWeekNames() {
    return ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];
  }
}
/**Formato custom de fechas */
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: 'MMMM YYYY',
  }
};

const customComponents = [
  ModalErrorComponent,
  SpinnerComponent,
  ModalErrorPerfiladoComponent,
  ButtonGroupComponent,
  LogoComponent,
  UserImgComponent,
  UserInfoComponent,
  MenuComponent,
  IconButtonComponent,
  UserMenuComponent,
  MenuSupportComponent,
  DirectAccessComponent,
  InputComponent,
  InputTallerComponent,
  ButtonComponent,
  ActionMessageComponent,
  SessionMessageComponent,
  DecisionModalComponent,
  TablaSeleccionComponent,
  TablaAccionesComponent,
  PaginadorComponent,
  DatepickerHeaderComponent,
  StepperComponent,
  BannerInformativoComponent,
  BuscadorComponent,
  MenuAccionesComponent
];

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    TranslateModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    MatDatepickerModule,
    MomentDateModule
  ],
  declarations: [
    customComponents
  ],
  exports: [
    customComponents,
    CoreModule,
    MaterialModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'es-ES'
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    }
  ]
})
/**
 * Módulo que incluye componentes comunes
 */
export class SharedModule { }
