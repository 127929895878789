import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Generic } from 'src/app/core/models/Generic';

@Component({
  selector: 'selector-input',
  templateUrl: './selector-input.component.html',
  styleUrls: ['./selector-input.component.scss']
})
export class SelectorInputComponent {
  /*FormControl del input*/
  @Input() control: UntypedFormControl;
  /*Etiqueta del campo
  al que corresponde el input*/
  @Input() controlLabel: string;
  /*Lista de valores
  que se muestran en el selector*/
  @Input() list: Generic[];

}
