import { Injectable } from '@angular/core';
import { CookieService as NgxCookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  /**
   * Método constructor
   * inyección de servicios
   * @param ngxCookieSrv servicio libreria
   */
  constructor(private ngxCookieSrv: NgxCookieService){}

  /**
     * Método que crea una nueva cookie
     * @param name nombre
     * @param value valor
     * @param expiration fecha expiracion
     */
  public crearCookie(name: string, value: string, expiration: Date | undefined) {
    const cookieExist = this.ngxCookieSrv.get(name);
    if (cookieExist) {
      this.eliminarCookie(name);
    }
    this.ngxCookieSrv.set(name, value, expiration, '/', undefined, true);
  }
  /**
   * Método que recupera una cookie
   * @param name nombre de la cookie
   * @returns valor de la cookie
   */
  public getCookie(name: string) {
    return this.ngxCookieSrv.get(name);
  }
  /**
   * Método que devuelve true o false
   * si existe la cookie
   * @param name nombre de la cookie
   * @returns boolean
   */
  public checkCookie(name: string) {
    return this.ngxCookieSrv.check(name);
  }
  /**
   * Método que elimina una cookie
   * específica
   * @param name nombre cookie
   */
  public eliminarCookie(name: string) {
    this.ngxCookieSrv.delete(name, '/', undefined, true);
  }
}
