import { Component, Input, inject } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { SessionService } from '@mutual-lib/frontend';
import { isNil } from 'lodash';
import { AsistenteService } from 'src/app/core/providers/asistente.service';
import { DniValidatorService } from 'src/app/core/providers/dni-validator.service';
import { UserServiceService } from 'src/app/core/providers/user-service.service';

@Component({
  selector: 'datos-documento',
  templateUrl: './datos-documento.component.html',
  styleUrls: ['./datos-documento.component.scss'],
})
export class DatosDocumentoComponent {
  @Input() formInputs!: any;
  public dniSrv: DniValidatorService;
  public asistenteSrv: AsistenteService;
  public userSrv: UserServiceService;
  public sessionSrv: SessionService;


  constructor() {
    this.dniSrv = inject(DniValidatorService);
    this.asistenteSrv = inject(AsistenteService);
    this.sessionSrv = inject(SessionService);
    this.userSrv = inject(UserServiceService);
  }

  async ngOnInit() {
    this.asistenteSrv.dniSuscription = this.asistenteSrv.ocrForm.controls['dni'].valueChanges
      .subscribe(newValueDni => {
        if (!isNil(newValueDni)) {
          this.validarDniNie(newValueDni);
        }
      });
  }

  /**
   * Metodo que valida si el
   * DNI o el NIE son correctos en caso
   * contrario sale un error para advertirnos
   * de que el formato es incorrecto
   */
  public validarDniNie(valueDniNie: string) {
    let controlDniNie = valueDniNie;
    const patronNum = /^\d/;
    const patronChar = /^[XYZxyz]/;
    const longitud = controlDniNie.length;
    const primeraPos = controlDniNie.charAt(0);
    if (longitud > 0 && (patronNum.test(primeraPos) || patronChar.test(primeraPos))) {
      controlDniNie = controlDniNie.padStart(9, '0');
      this.isNieNif(controlDniNie);
    } else {
      this.asistenteSrv.ocrForm.controls['dni']?.setErrors({
        invalido: true,
        required: this.asistenteSrv.ocrForm.controls['dni']?.getError('required')
      });
    }
  }

  /**
   * Metodo que nos confirma si es valido
   * el Nie o el Nif del cliente
   */
  public isNieNif(controlDniNie: string) {
    if (this.dniSrv.validator(controlDniNie)) {
      this.asistenteSrv.ocrForm.controls['dni']?.setErrors(null);
      this.asistenteSrv.ocrForm.controls['dni'].markAsUntouched();
    } else {
      this.asistenteSrv.ocrForm.controls['dni']?.setErrors({
        invalido: true,
        required: this.asistenteSrv.ocrForm.controls['dni']?.getError('required')
      });
      this.asistenteSrv.ocrForm.controls['dni'].markAllAsTouched();
    }
  }

  /**
   * Acceso al form control dni
   */
  get dni(): AbstractControl | null {
    return this.asistenteSrv.ocrForm.get('dni');
  }
  /**
   * Acceso al form control nacimiento
   */
  get fechacaducidadnif(): AbstractControl | null {
    return this.asistenteSrv.ocrForm.get('fechacaducidadnif');
  }
  /**
   * Acceso al form control nacimiento
   */
  get fecha(): AbstractControl | null {
    return this.asistenteSrv.ocrForm.get('fecha');
  }
}
