import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, inject } from '@angular/core';
import { UserServiceService } from './user-service.service';
import Config from 'src/assets/config/Config.json';
import { Observable, Subscription } from 'rxjs';
import { OCREnvio } from '../models/ocr-envio';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import base64url from "base64url";
import { ApiToken } from 'src/app/core/models/api-token';
import { Pagina } from 'src/app/core/enums/pagina.enum';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { LevelType, LogService, SessionService, TokenService, UtilsService } from '@mutual-lib/frontend';
@Injectable({
  providedIn: 'root'
})
export class AsistenteService {
  dniBase64 = { "anv": "", "rev": "" };
  dniNombre = { "anv": "", "rev": "" };
  /*Flag que activa la ventana
  de la webcam*/
  showWebcam: boolean = false;
  /*Flag que activa la ventana de seleccionar
  archivo o webcam*/
  showCaptura: boolean = true;
  ocrForm: UntypedFormGroup;
  /*Flag que marca si
  se muestra los inputs
  en el formulario general*/
  showInputsOCR: boolean = false;
  /*Flag que marca si
  los campos del OCR son editables
  por algún error en
  el procesamiento de las imágenes*/
  camposEditables!: boolean;
  finAsistente: EventEmitter<any> = new EventEmitter();
  selectorCaraDni: any = [
    { "label": "anv" },
    { "label": "rev" }
  ];
  dniCaraSrv: string = "anv";
  currentToken: ApiToken;
  showInputs: boolean = false;
  load: boolean = false;
  /**
  * Numero de la pagina donde estamos
  */
  pagina = Pagina;
  /**
   * variables para el mat-stepper
   */
  isCompletedAnv!: boolean;
  isCompletedRev!: boolean;
  posDNI!: number;
  /*Variable que guarda el error
  en caso de que no haya dispositivo de cámara*/
  hasWebcam!: boolean;
  responsiveCDK!: boolean;
  /**
   * Varible que guarda la subscripcion
   * para despues poder desubscribirse
   */
  ocrSubscription!: Subscription;
  dniSuscription!: Subscription;
  /**
  * Se inyectan los servicios necesarios,
  * se revisa el navegador para cargar la imagen
  * principal en version movil o desktop,
  * se revisa la url para cambiar las imágenes
  * según el idioma y se crea el formulario
  * @param injector
  */
  public formBuilder: UntypedFormBuilder;
  public http: HttpClient;
  public userSrv: UserServiceService;
  public logSrv: LogService;
  public breakpointObserver: BreakpointObserver;
  public utilsSrv: UtilsService;
  public sessionSrv: SessionService;
  public tokenSrv: TokenService;
  public utilSrv: UtilsService;

  constructor() {
    this.tokenSrv = inject(TokenService);
    this.formBuilder = inject(UntypedFormBuilder);
    this.http = inject(HttpClient);
    this.userSrv = inject(UserServiceService);
    this.logSrv = inject(LogService);
    this.breakpointObserver = inject(BreakpointObserver);
    this.utilsSrv = inject(UtilsService);
    this.sessionSrv = inject(SessionService);
    this.utilSrv = inject(UtilsService);
  }


  /**
   * Método donde se inicializan
   * todas las varibles para el OCR
   */
  public inicializar() {
    this.dniCaraSrv = "anv";
    this.showCaptura = true;
    this.showWebcam = false;
    this.showInputs = false;
    this.dniBase64['anv'] = "";
    this.dniBase64['rev'] = "";
    this.dniNombre['anv'] = "";
    this.dniNombre['rev'] = "";
    this.posDNI = 0;
    this.isCompletedAnv = false;
    this.isCompletedRev = false;
    this.userSrv.resetControls("dnianv");
    this.userSrv.resetControls("dnirev");
    this.userSrv.resetControls("dnianvNombre");
    this.userSrv.resetControls("dnirevNombre");
    Object.keys(this.ocrForm.controls).forEach((k) => {
      this.ocrForm.controls[k].enable();
    });
    this.styleCDK();
  }

  /**
   * Método que guarda el base64
   * y el nombre de la imagen hecha
   * por webcam
   * @param base64
   * @param dniCara
   */
  public guardadoDniWebcam(base64: string, dniCara: string) {
    this.dniBase64[dniCara] = base64;
    this.dniNombre[dniCara] = dniCara + '.png';

  }

  /**
    * Metodo que
    * envía el anverso y
    * reverso del dni en base64 para
    * el procesamiento
    * del OCR
    * @param lang
    * @param traceid
    */
  callOCREnvio(traceid: string, docAnverso: string, docReverso: string): Observable<OCREnvio> {
    const headers: any = this.utilSrv.setTraceIdHeader(traceid);
    const body = {
      "docAnverso": docAnverso,
      "docReverso": docReverso
    }
    return this.http.post<OCREnvio>(Config.apiUrl + '/OCR/resultados', body, { headers });
  }

  createOCRForm() {
    this.ocrForm = this.formBuilder.group({
      dni: ['', Validators.required],
      fechacaducidadnif: ['', Validators.required],
      fecha: ['', Validators.required]
    })
  }

  /**
   * Llamada al envio
   * de OCR Y gestión de
   * los datos recibidos
   */
  async pasoInputs() {
    this.sessionSrv.showSpinner = true;
    const anv = base64url.fromBase64(this.dniBase64['anv'].split("base64,").pop());
    const rev = base64url.fromBase64(this.dniBase64['rev'].split("base64,").pop());
    if (this.tokenSrv.isTokenExpired()) {
      await this.tokenSrv.getWSO2Token();
    }
    const traceId = this.userSrv.generateTraceId();
    this.logSrv.insertLog(traceId, 'OCR', 'click', LevelType.info, 'Enviar imagenes OCR', 'Usuario');
    this.ocrSubscription = this.callOCREnvio(traceId, anv, rev).subscribe({
      next: (obj: OCREnvio) => {
        this.logSrv.insertLog(traceId, 'OCR', 'enviarOCR', LevelType.info, 'Respuesta recibida OK OCR', 'Usuario');
        this.validarDatosOcr(obj);

      }, error: () => {
        this.logSrv.insertLog(traceId, 'OCR', 'enviarOCR', LevelType.info, 'Error al procesar imagenes OCR', 'Usuario');
        this.editarInputs();
      }
    });
  }

  /**
   * Método que valida si los datos
   * recibidos del OCR son válidos
   * @param jsonOCR
  */
  validarDatosOcr(jsonOCR: any) {
    if (!this.utilsSrv.compararValores(jsonOCR.validacionGlobal, "OK") || (!this.utilsSrv.compararValores(jsonOCR.validacionNumDoc, "OK")
      && !this.utilsSrv.compararValores(jsonOCR.validacionFechaNac, "OK")
      && !this.utilsSrv.compararValores(jsonOCR.validacionFechaExp, "OK"))) {
      this.editarInputs();
    }
    else {
      jsonOCR.numDocumento = this.utilsSrv.compararValores(jsonOCR.validacionNumDoc, "OK") ? jsonOCR.numDocumento : '';
      jsonOCR.fechaNacimiento = this.utilsSrv.compararValores(jsonOCR.validacionFechaNac, "OK") ? new Date(jsonOCR.fechaNacimiento) : '';
      jsonOCR.fechaExpiracion = this.utilsSrv.compararValores(jsonOCR.validacionFechaExp, "OK") ? new Date(jsonOCR.fechaExpiracion) : '';
      this.setValuesInputs(jsonOCR);
    }
  }

  /**
  * Método que permite
  * resetear los valores
  * del formulario
  * y editar los campos
  * de los inputs
  */
  public editarInputs() {
    this.ocrForm.reset();
    this.resetDNI(true);
  }

  /**
   * Método que setea
   * los valores del formulario
   * con los recibidos del OCR
   * @param results
   */
  setValuesInputs(results: OCREnvio) {
    this.ocrForm.controls['dni'].setValue(results.numDocumento);
    this.ocrForm.controls['fecha'].setValue(results.fechaNacimiento);
    this.ocrForm.controls['fechacaducidadnif'].setValue(results.fechaExpiracion);
    this.resetDNI(false);
  }

  /**
   * Método que habilita o
   * deshabilita los campos del
   * OCR
   * @param isBoolean
   */
  public resetDNI(isBoolean: boolean) {
    this.sessionSrv.showSpinner = false;
    this.camposEditables = isBoolean;
    this.showInputs = true;
    this.ocrSubscription?.unsubscribe();
  }

  /**
   * Metodo que nos
   * valida el estilo interno
   * CDK
   */
  public styleCDK() {
    this.checkResponsiveCDK();
    if (this.responsiveCDK) {
      const cdk = document.getElementsByClassName("cdk-overlay-pane");
      cdk[0].setAttribute('style', 'max-width: 80vw; position: static; overflow-y: scroll;');
    }
  }

  /**
   * Método que observa
   * si la pantalla esta en responsive
   */
  public checkResponsiveCDK() {
    this.breakpointObserver.observe(['(max-width: 700px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.responsiveCDK = true;
        } else {
          this.responsiveCDK = false;
        }
      });
  }

  /**
   * Método para
   * desuscribirse de los eventos
   */
  ngOnDestroy() {
    this.breakpointObserver?.ngOnDestroy();
  }

  /**
   * Método para parar todos
   * los MediaStreams
   * @param hasVideoDevice
   */
  closeAllMediaStream(hasVideoDevice: boolean) {
    navigator.mediaDevices.getUserMedia({ video: hasVideoDevice }).then((mediaStream) => {
      mediaStream.getTracks()
        .forEach(track => track.stop());
    })
  }

  asistenteFinalizado() {
    this.finAsistente.emit();
  }
}
